import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname, state } = useLocation();
  const scrollRef = useRef(null);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      block: "start",
    });
  }, [pathname, state]);
  return <div ref={scrollRef}></div>;
};

export default ScrollToTop;
