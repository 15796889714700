import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { default as ModalProvider } from "react-modal";
import { collection, doc, getFirestore, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import LoaderModal from "../../modals/LoaderModal";
import { editSavedAddress } from "../../store/slices/cartSlice";
import { Button, Img, SelectBox } from "../../components";

const EditAddressModal = ({ data, ...props }) => {
  const auth = getAuth();
  const dispatch = useDispatch();
  const countryRegionOptionsList = [{ label: "India", value: "India" }];
  const stateOptionsList = [
    { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
    { label: "Andhra Pradesh", value: "Andhra Pradesh" },
    { label: "Assam", value: "Assam" },
    { label: "Bihar", value: "Bihar" },
    { label: "Chhattisgarh", value: "Chhattisgarh" },
    { label: "Goa", value: "Goa" },
    { label: "Gujarat", value: "Gujarat" },
    { label: "Haryana", value: "Haryana" },
    { label: "Himachal Pradesh", value: "Himachal Pradesh" },
    { label: "Jharkhand", value: "Jharkhand" },
    { label: "Karnataka", value: "Karnataka" },
    { label: "Kerala", value: "Kerala" },
    { label: "Maharashtra", value: "Maharashtra" },
    { label: "Madhya Pradesh", value: "Madhya Pradesh" },
    { label: "Manipur", value: "Manipur" },
    { label: "Meghalaya", value: "Meghalaya" },
    { label: "Mizoram", value: "Mizoram" },
    { label: "Nagaland", value: "Nagaland" },
    { label: "Odisha", value: "Odisha" },
    { label: "Punjab", value: "Punjab" },
    { label: "Rajasthan", value: "Rajasthan" },
    { label: "Sikkim", value: "Sikkim" },
    { label: "Tamil Nadu", value: "Tamil Nadu" },
    { label: "Tripura", value: "Tripura" },
    { label: "Telangana", value: "Telangana" },
    { label: "Uttar Pradesh", value: "Uttar Pradesh" },
    { label: "Uttarakhand", value: "Uttarakhand" },
    { label: "West Bengal", value: "West Bengal" },
    { label: "Andaman & Nicobar (UT)", value: "Andaman & Nicobar (UT)" },
    { label: "Chandigarh (UT)", value: "Chandigarh (UT)" },
    {
      label: "Dadra & Nagar Haveli and Daman & Diu (UT)",
      value: "Dadra & Nagar Haveli and Daman & Diu (UT)",
    },
    {
      label: "Delhi [National Capital Territory (NCT)]",
      value: "Delhi [National Capital Territory (NCT)]",
    },
    { label: "Jammu & Kashmir (UT)", value: "Jammu & Kashmir (UT)" },
    { label: "Ladakh (UT)", value: "Ladakh (UT)" },
    { label: "Lakshadweep (UT)", value: "Lakshadweep (UT)" },
    { label: "Puducherry (UT)", value: "Puducherry (UT)" },
  ];

  const [name, setName] = useState(data?.name ? data.name : "");
  const [country, setCountry] = useState(data?.country ? data.country : "");
  const [state, setState] = useState(data?.state ? data.state : "");
  const [city, setCity] = useState(data?.city ? data.city : "");
  const [street, setStreet] = useState(data?.street ? data.street : "");
  const [postalCode, setPostalCode] = useState(
    data?.postalCode ? data.postalCode : ""
  );
  const [fullAddress, setFullAddress] = useState(
    data?.fullAddress ? data.fullAddress : ""
  );

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const addressSchema = Yup.object().shape({
    name: Yup.string().min(1, "Name is required !"),
    country: Yup.string().min(1, "Country name is required !"),
    state: Yup.string().min(1, "state name is required !"),
    city: Yup.string().min(1, "City is required !"),
    street: Yup.string().min(1, "street address required !"),
    postalCode: Yup.number("Invalid pin code !").min(6, "Invalid pin code !"),
    fullAddress: Yup.string().min(1, "Please enter your full address !"),
  });

  const handleSave = async () => {
    try {
      await addressSchema.validate(
        {
          name,
          country,
          state,
          city,
          street,
          postalCode,
          fullAddress,
        },
        { abortEarly: false }
      );
      setErrors({});
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        // Extracting Yup specific validation errors from list of total errors
        const yupErrors = {};
        error.inner.forEach((innerError) => {
          yupErrors[innerError.path] = innerError.message;
        });

        // Saving extracted errors
        setErrors(yupErrors);
        toast.error("please fill all details correctly !");
        return;
      }
    }
    await auth.authStateReady();
    const user = auth.currentUser;
    if (user && data.id) {
      setLoading(true);

      const docRef = doc(collection(getFirestore(), "Addresses"), data.id);

      await setDoc(
        docRef,
        {
          name: name != "" ? name : user.displayName,
          country,
          state,
          city,
          street,
          postalCode,
          fullAddress,
          userId: user.uid,
        },
        { merge: true }
      )
        .then(() => {
          dispatch(
            editSavedAddress({
              name: name != "" ? name : user.displayName,
              country,
              state,
              city,
              street,
              postalCode,
              fullAddress,
              userId: user.uid,
              id: docRef.id,
            })
          );
          setLoading(false);
          toast.success("address updated successfully !");
          props.setOpen(false);
        })
        .catch((error) => {
          console.log(error);
          toast.error("Something went wrong !");
          setLoading(false);
        });
    }
  };

  const handleCancel = () => {
    props.setOpen(false);
  };

  return (
    <ModalProvider
      appElement={document.getElementById("root")}
      className="sm:!w-[600px] m-auto w-[90vw]  "
      overlayClassName=" z-20 bg-black_900_7f fixed h-[100vh] overflow-y-scroll no-scrollbar flex  inset-y-[0] w-full"
      {...props}
      onAfterOpen={() => {
        let doc = document.getElementById("root");
        doc.style.height = "100vh";
        doc.style.overflowY = "hidden";
      }}
      onAfterClose={() => {
        document.getElementById("root").style.overflowY = "scroll";
      }}
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        props.setOpen(false);
      }}
    >
      <div className="bg-white rounded-md ">
        <LoaderModal isOpen={loading}>
          <div>Saving Address</div>
        </LoaderModal>

        <div className="p-4 text-left sm:p-8 md:p-10 lg:p-14">
          <div className="my-3 ">
            <div>Deliver to*</div>
            {errors?.name && (
              <div className="flex justify-end text-[9.2px] px-3 md:text-xs text-red-600 ">
                {errors.name}
              </div>
            )}
            <input
              type="text"
              value={name}
              className={`w-full h-10 p-2 mt-1 border border-solid sm:h-12 ${
                errors.name ? "border-red-600" : "border-gray_700"
              } bg-gray_100`}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
          </div>

          {errors?.country && (
            <div className="flex justify-end text-[9.2px] px-3 sm:w-4/5 w-full md:text-xs text-red-600 ">
              {errors.country}
            </div>
          )}
          <SelectBox
            className={`w-full text-base text-left border border-solid ${
              errors.country ? "border-red-600" : "border-gray_700"
            } sm:w-4/5`}
            placeholderClassName="text-gray_700_cc_01"
            indicator={
              <Img
                className="h-6 mr-[0] w-6"
                src="images/img_arrowdown_gray_700.svg"
                alt="arrow_down"
              />
            }
            isMulti={false}
            name="country"
            options={countryRegionOptionsList}
            isSearchable={false}
            placeholder={` ${country != "" ? country : "Country/Region*"}`}
            shape="square"
            color="gray_100"
            size="sm"
            variant="fill"
            onChange={setCountry}
            value={country}
          />
          <div className="grid gap-4 sm:grid-cols-2 ">
            <div className="mt-7">
              {errors?.state && (
                <div className="flex justify-end text-[9.2px] px-3 md:text-xs text-red-600 ">
                  {errors.state}
                </div>
              )}
              <SelectBox
                className={`text-base text-left border border-solid ${
                  errors.state ? "border-red-600" : "border-gray_700"
                } border-gray_700`}
                placeholderClassName="text-gray_700_cc_01"
                indicator={
                  <Img
                    className="h-6 mr-[0] w-6"
                    src="images/img_arrowdown_gray_700.svg"
                    alt="arrow_down"
                  />
                }
                isMulti={false}
                name="frame1000006172"
                options={stateOptionsList}
                isSearchable={false}
                placeholder={`${state != "" ? state : "State*"}`}
                shape="square"
                color="gray_100"
                size="sm"
                variant="fill"
                onChange={setState}
                value={state}
              />
            </div>
            <div>
              <div>City*</div>
              {errors?.city && (
                <div className="flex justify-end text-[9.2px] px-3 md:text-xs text-red-600 ">
                  {errors.city}
                </div>
              )}
              <input
                type="text"
                value={city}
                className={`w-full h-10 p-2 mt-1 border border-solid sm:h-12 ${
                  errors.city ? "border-red-600" : "border-gray_700"
                } bg-gray_100`}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div>
              <div>Street*</div>
              {errors?.street && (
                <div className="flex justify-end text-[9.2px] px-3 md:text-xs text-red-600 ">
                  {errors.street}
                </div>
              )}
              <input
                type="text"
                value={street}
                className={`w-full h-10 p-2 mt-1 border border-solid sm:h-12 ${
                  errors.street ? "border-red-600" : "border-gray_700"
                } bg-gray_100`}
                onChange={(e) => setStreet(e.target.value)}
              />
            </div>
            <div>
              <div>Postal Code*</div>
              {errors?.postalCode && (
                <div className="flex justify-end text-[9.2px] px-3 md:text-xs text-red-600 ">
                  {errors.postalCode}
                </div>
              )}
              <input
                type="number"
                value={postalCode}
                className={`w-full h-10 p-2 mt-1 border border-solid sm:h-12 ${
                  errors.postalCode ? "border-red-600" : "border-gray_700"
                } bg-gray_100`}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-4 ">
            <div>Address*</div>
            {errors?.fullAddress && (
              <div className="flex justify-end text-[9.2px] px-3 md:text-xs text-red-600 ">
                {errors.fullAddress}
              </div>
            )}
            <textarea
              type="text"
              className={`w-full min-h-[160px] p-2 mt-1  border border-solid sm:h-12 ${
                errors.fullAddress ? "border-red-600" : "border-gray_700"
              } bg-gray_100`}
              style={{ color: "#605f5f" }}
              value={fullAddress}
              onChange={(e) => setFullAddress(e.target.value)}
            />
          </div>
          <div className="flex flex-row text-[10.21px] my-4 sm:text-base font-medium items-center justify-end gap-4 ">
            <div
              className="px-6 py-2 cursor-pointer "
              onClick={() => handleCancel()}
            >
              Cancel
            </div>
            <Button
              variant="fill"
              color="gray_700"
              className="px-6 py-2 uppercase "
              onClick={() => handleSave()}
            >
              SAVE CHANGES
            </Button>
          </div>
        </div>
      </div>
    </ModalProvider>
  );
};

export default EditAddressModal;
