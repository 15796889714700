import React, { useState } from "react";

import EditAddressModal from "../../../modals/EditAddressModal";

const CheckOutAddressCard = ({ children, item }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="flex flex-row items-center justify-between w-full gap-3 p-4 border border-solid flex-nowrap border-gray_700">
      <EditAddressModal isOpen={isOpen} setOpen={setOpen} data={item} />
      <div className=" sm:w-1/3 font-medium text-[6.35px] sm:text-[17.11px] flex flex-row flex-nowrap items-center space-x-3">
        {children}
        <div>{item.name?.split(" ")[0]}</div>
      </div>
      <div className="flex sm:w-1/3  gap-1 text-[5.34px] sm:text-[14.41px] ">
        <div>{`${item.street} ${item.city} ${item.state}  ${item.country}, ${item.postalCode}`}</div>
      </div>
      <div className="flex justify-end sm:w-1/3">
        <div
          className="underline cursor-pointer text-[5.68px] sm:text-[15.31px] "
          onClick={() => setOpen(true)}
        >
          Edit
        </div>
      </div>
    </div>
  );
};

export default CheckOutAddressCard;
