import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: [],
    selectedAddress: {},
    deliveryAddress: [],
    totalQuantity: 0,
  },
  reducers: {
    setCart(state, action) {
      state.cartItems = action.payload;
      state.totalQuantity = action.payload.length;
    },
    addToCart(state, action) {
      const cartItemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );

      if (cartItemIndex >= 0) {
        state.cartItems[cartItemIndex].cartQuantity += 1;
        state.cartItems[cartItemIndex].subTotal += Number(
          action.payload.discountedPrice
        );
      } else {
        const product = {
          ...action.payload,
          cartQuantity: 1,
          subTotal: Number(action.payload.discountedPrice),
        };
        state.cartItems.push(product);
        state.totalQuantity += 1;
      }
    },
    removeFromCart(state, action) {
      const inCartItems = state.cartItems.filter(
        (cartItem) => cartItem.id !== action.payload.id
      );
      state.cartItems = inCartItems;
      state.totalQuantity -= 1;
    },
    deleteAllFromCart(state, action) {
      state.cartItems = [];
      state.totalQuantity = 0;
    },
    moveAllFromFavourite(state, action) {
      const items = action.payload;
      items.forEach((item) => {
        if (item.isAvailable && item.isVisible) {
          const index = state.cartItems.findIndex(
            (curr) => curr.id === item.id
          );
          if (index >= 0) {
            state.cartItems[index].cartQuantity += 1;
            state.cartItems[index].subTotal += Number(item.discountedPrice);
          } else {
            state.cartItems.push({
              ...item,
              cartQuantity: 1,
              subTotal: Number(item.discountedPrice),
            });
            state.totalQuantity += 1;
          }
        }
      });
    },
    decreaseQuantity(state, action) {
      const cartItemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );
      if (
        cartItemIndex >= 0 &&
        state.cartItems[cartItemIndex].cartQuantity > 1
      ) {
        state.cartItems[cartItemIndex].cartQuantity -= 1;
        state.cartItems[cartItemIndex].subTotal -= Number(
          action.payload.discountedPrice
        );
      }
    },
    setSelectedAddress(state, action) {
      state.selectedAddress = action.payload;
    },
    setDeliveryAddresses(state, action) {
      state.deliveryAddress = action.payload;
    },
    editSelectedAddress(state, action) {
      const itemIndex = state.deliveryAddress.findIndex(
        (ele) => ele.id === action.payload.id
      );
      if (itemIndex >= 0) {
        state.deliveryAddress[itemIndex] = action.payload;
      }
      state.selectedAddress = action.payload;
    },
    editSavedAddress(state, action) {
      const itemIndex = state.deliveryAddress.findIndex(
        (ele) => ele.id === action.payload.id
      );
      if (itemIndex >= 0) {
        state.deliveryAddress[itemIndex] = action.payload;
        if (state.deliveryAddress[itemIndex].id === state.selectedAddress.id) {
          state.selectedAddress = action.payload;
        }
      } else {
        state.deliveryAddress.push(action.payload);
      }
    },
  },
});
export default cartSlice.reducer;
export const {
  addToCart,
  removeFromCart,
  deleteAllFromCart,
  moveAllFromFavourite,
  decreaseQuantity,
  setSelectedAddress,
  editSavedAddress,
  editSelectedAddress,
  setDeliveryAddresses,
  setCart,
} = cartSlice.actions;
