import React from "react";

import { useNavigate } from "react-router-dom";

import { Button, Img, Line, Text } from "../../components";

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col items-center justify-start w-full mx-auto bg-white-A700">
        <div className="flex flex-col gap-[34px] items-center justify-start max-w-[879px] mt-[278px] mx-auto md:px-5 w-full">
          <Text
            className=" text-lg md:text-5xl text-gray-700_01 tracking-[3.50px]"
            size="txtInterMedium11663"
          >
            404 Not Found
          </Text>
          <Text
            className=" text-xs md:text-[16.97px] text-gray-700_01"
            size="txtPoppinsRegular1697"
          >
            Your visited page not found. You may go home page.
          </Text>
        </div>
        <Button
          className="common-pointer cursor-pointer font-medium font-poppins min-w-[268px] mt-[82px] text-xs md:text-[16.97px] text-center"
          onClick={() => navigate("/")}
          shape="square"
          color="gray_700"
          size="lg"
          variant="fill"
        >
          Back to home page
        </Button>
      </div>
    </>
  );
};

export default ErrorPage;
