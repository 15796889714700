import React from "react";

const TagsField = ({ tags, setTags, maxTagCount = 20, className }) => {
  const [error, setError] = React.useState("");

  // Add or remove tags by using the key
  const handleTags = (event) => {
    if (
      event.key === "Enter" &&
      event.target.value !== "" &&
      tags.length < maxTagCount &&
      !tags.includes(event.target.value)
    ) {
      setTags([...tags, event.target.value]);
      event.target.value = "";
    } else if (
      event.key === "Backspace" &&
      tags.length &&
      event.target.value == 0
    ) {
      const tagsCopy = [...tags];
      tagsCopy.pop();
      event.preventDefault();
      setTags(tagsCopy);
    } else if (tags.length >= maxTagCount) {
      setError("You can't add more tags");
    } else if (event.target.value == "" && event.key === "Enter") {
      setError("The tag should be one character long!");
    }
  };

  //Remove tags by clicking the cross sign
  const removeTags = (index) => {
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
  };

  //To update the error after user changes something
  const handleError = () => {
    setError("");
  };

  return (
    <>
      <div>
        <input
          type="text"
          onKeyDown={(event) => handleTags(event)}
          onChange={handleError}
          placeholder="write and press enter to save"
          className={`${className} placeholder:text-xs`}
        />
        <div className="flex flex-wrap gap-3 p-2 ">
          {tags.map((tag, index) => (
            <div
              className="flex gap-4 p-1 border border-solid rounded-md border-gray_700"
              key={index}
            >
              <span>{tag}</span>
              <div
                onClick={() => removeTags(index)}
                className="flex items-center justify-center h-6 font-semibold text-white rounded-full cursor-pointer bg-gray_700 aspect-square"
              >
                x
              </div>
            </div>
          ))}{" "}
        </div>
      </div>

      <div className="error">{error}</div>
    </>
  );
};
export { TagsField };
