import React, { useEffect, useState } from "react";
import "./styles.css";
import { Img, Text } from "../";
import NotificationsModal from "../../modals/Notifications";
import SignupOneModal from "../../modals/SignupOne";
import MyAccountModal from "../../modals/MyAccountModal";
import SelectAddressModal from "../../modals/SelectAddressModal";
import SearchModal from "../../modals/SearchModal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setOpenLogin } from "../../store/slices/userSlice";

const Header = () => {
  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector((state) => {
    return state.user;
  });
  const cartSize = useSelector((state) => {
    return state.cart.totalQuantity;
  });
  const wishlistSize = useSelector((state) => {
    return state.wishList.totalQuantity;
  });
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [isOpenAccModal, setOpenAccModal] = useState(false);
  const [isOpenLocModal, setOpenLocModal] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <header className="grid items-center w-full grid-cols-3 gap-2 px-4  align-middle border-b-2 border-solid sm:px-12 bg-white-A700 border-blue_gray-900_7f md:gap-5 md:px-14 lg:px-20 desktop:w-[80%] desktop:mx-auto">
        <SignupOneModal />
        <MyAccountModal isOpen={isOpenAccModal} setOpen={setOpenAccModal} />
        <SelectAddressModal isOpen={isOpenLocModal} setOpen={setOpenLocModal} />
        <SearchModal isOpen={isOpenSearch} setOpen={setIsOpenSearch} />
        <div className="flex items-center space-x-1 sm:space-x-2 md:space-x-4 ">
          <div
            className="flex items-center space-x-1 scale-90 cursor-pointer sm:scale-100 sm:space-x-2"
            onClick={() => setOpenLocModal(true)}
          >
            <div className="w-6 scale-[0.6]  aspect-square sm:scale-100 location-img">
              <Img src="/images/img_search.svg" alt="__location" />
            </div>
            <Text className="hidden text-xs xl:text-base md:block">
              LOCATION
            </Text>
          </div>
          {!isLoggedIn ? (
            <div
              className="flex items-center space-x-1 cursor-pointer sm:space-x-2 "
              onClick={() => dispatch(setOpenLogin(true))}
            >
              <Img
                src="/images/img_user_gray_700.svg"
                alt="user"
                className="w-4 sm:w-6 aspect-square"
              />
              <Text className="text-xs underline xl:text-base">SIGN IN</Text>
            </div>
          ) : (
            <div className="flex items-center space-x-1 sm:space-x-2">
              <Img
                src="/images/img_user_gray_700.svg"
                alt="user"
                className="w-4 cursor-pointer sm:w-6 aspect-square"
                onClick={() => setOpenAccModal(true)}
              />
              <Text
                className="overflow-hidden text-xs underline align-middle cursor-pointer text-gray_700 xl:text-base"
                onClick={() => setOpenAccModal(true)}
              >
                {user.displayName?.split(" ")[0]}
              </Text>
            </div>
          )}
        </div>
        <Text
          onClick={() => navigate("/")}
          className="flex flex-row items-center justify-center gap-1 text-xl font-semibold text-center cursor-pointer sm:gap-2 sm:text-2xl md:text-4xl"
        >
          <Img
            src="images/IndilistLogo.jpeg"
            className="h-20 md:h-24 lg:h-28 aspect-square"
          />
          {/* INDILIST */}
        </Text>
        <div className="flex items-center justify-end space-x-2 sm:space-x-4">
          <Img
            src="/images/img_rewind.svg"
            alt="rewind"
            className="w-[15.86px] sm:w-6 h-[15.86px] sm:h-6  cursor-pointer"
            onClick={() => setIsOpenSearch(true)}
          />
          <div
            className="scale-75 cursor-pointer count-container sm:scale-100"
            onClick={() => navigate("/wishlist")}
          >
            <Img
              src="/images/img_favorite.svg"
              alt="favorite"
              className="w-5 sm:w-6 aspect-square"
            />
            {wishlistSize > 0 && (
              <Text className="count " size="txtInterSemiBold9">
                {wishlistSize > 0 && wishlistSize}
              </Text>
            )}
          </div>
          <div
            className="scale-75 cursor-pointer sm:scale-100 count-container"
            onClick={() => navigate("/cart")}
          >
            <Img
              src="/images/img_bag.svg"
              alt="bag"
              className="w-5 sm:w-6 aspect-square "
            />
            {cartSize > 0 && (
              <Text className="count" size="txtInterSemiBold9">
                {cartSize > 0 && cartSize}
              </Text>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

Header.defaultProps = {};

export default Header;
