import React from "react";

import { Button, Img, Line, Text } from "../";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";

const Footer = (props) => {
  const user = useSelector((state) => {
    return state.user?.user;
  });
  const navigate = useNavigate();
  return (
    <>
      <footer className={props.className}>
        <div className="w-full px-8 my-12 md:px-14 lg:px-20 desktop:w-[80%] ">
          <div className="flex flex-col items-center justify-center w-full font-inter">
            <div className="flex-row w-full space-x-6 text-center md:text-left lg:flex ">
              <div className="flex flex-col items-center md:items-start justify-between leading-8 tracking-[1.71px]   text-lg text-white_A700 md:flex-row w-full ">
                {/* <div className="my-3 md:w-full ">
                  <p>DISCOVER</p>
                  <ul className="px-4 ">
                    <li className="mx-auto list-disc md:mx-0 w-fit ">
                      <a href="">Deals of the Day</a>
                    </li>
                    <li className="mx-auto list-disc md:mx-0 w-fit ">
                      <a href="">Most Searched</a>
                    </li>
                    <li className="mx-auto list-disc md:mx-0 w-fit ">
                      <a href="">Most Selled</a>
                    </li>
                  </ul>
                </div> */}
                <div className="my-3 md:w-full">
                  <p>OUR COMPANY</p>
                  <ul className="px-5">
                    <li className="mx-auto list-disc md:mx-0 w-fit">
                      <Link to="/aboutus">About</Link>
                    </li>
                    <li className="mx-auto list-disc md:mx-0 w-fit">
                      <a href="mailto:business@indilist.com?subject =Contact">
                        Contact Us
                      </a>
                    </li>
                    {/* <li className="mx-auto list-disc md:mx-0 w-fit ">
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li> */}
                    <li className="mx-auto list-disc md:mx-0 w-fit ">
                      <Link to="/terms-&-conditions">Terms & Conditions</Link>
                    </li>
                  </ul>
                </div>
                <div className="my-3 md:w-full ">
                  {/* <p className="uppercase "> Customer Support</p> */}
                  <ul className="px-4">
                    <li className="mx-auto list-disc md:mx-0 w-fit ">
                      <a href="mailto:business@indilist.com?subject=Help">
                        Help
                      </a>
                    </li>
                    {/* <li className="mx-auto list-disc md:mx-0 w-fit ">
                      <HashLink to="/terms-&-conditions/#shipping-&-delivery">
                        Delivery
                      </HashLink>
                    </li> */}
                    <li className="mx-auto list-disc md:mx-0 w-fit ">
                      <div
                        className="cursor-pointer "
                        onClick={() => {
                          user != null
                            ? navigate("/seller-home")
                            : navigate("/seller-signup");
                        }}
                      >
                        Sell on Indilist
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              {/* <div className="mt-0 text-left md:mt-4 lg:w-1/3">
                <p className="leading-[27.00px] text-sm text-white_A700 tracking-[1.26px] ">
                  Be the first to know about exciting newdesigns, special
                  events, store openings and much more.
                </p>

                <div
                  className="mt-[30px] text-left text-white_A700 text-xl tracking-[1.80px]"
                  size="txtInterRegular20WhiteA700"
                >
                  Email
                </div>
                <Line className="bg-blue_gray_100 h-px mt-1 w-[87%]" />
                <Button
                  className="cursor-pointer font-inter px-10  mt-8 text-xl text-center sm:text-lg md:text-xl tracking-[1.98px]"
                  shape="square"
                  color="white_A700"
                  size="sm"
                  variant="outline"
                >
                  Submit
                </Button>
              </div> */}
            </div>
            <Line className="bg-white_A700 h-px md:ml-[0] ml-[3px] mt-[65px] w-full" />

            <div className="flex flex-col sm:flex-row gap-9 items-center justify-between md:ml-[0] mt-8 w-full px-8">
              <div className="text-left text-white ">
                Address : <br />
                <address>
                  Mayflower Sakthi gardens,
                  <br /> nanjundapuram road, <br /> Coimbatore, 641045 <br />
                  Email : business@indilist.com
                </address>
              </div>
              <div className="flex flex-row gap-9 ">
                <a
                  href="https://www.instagram.com/shopindilist/"
                  target="_blank"
                >
                  <Img
                    className=" h-6 w-6 md:h-[30px] md:w-[30px]"
                    src="/images/img_instagram.svg"
                    alt="instagram"
                  />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61556687621005"
                  target="_blank"
                >
                  <Img
                    className="h-6 w-6 md:h-[30px] md:w-[30px]"
                    src="/images/img_facebook.svg"
                    alt="facebook"
                  />
                </a>
                <a href="https://twitter.com/shopindilist" target="_blank">
                  <Img
                    className="h-6 w-6 md:h-[30px] md:w-[30px]"
                    src="/images/img_trash.svg"
                    alt="trash"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

Footer.defaultProps = {};

export default Footer;
