import React from "react";
import { default as ModalProvider } from "react-modal";
import { HashLoader } from "react-spinners";

const LoaderModal = ({ children, isOpen, ...props }) => {
  return (
    <ModalProvider
      appElement={document.getElementById("root")}
      className="w-full "
      overlayClassName="bg-black_900_7f z-20 fixed flex h-full inset-y-[0] w-full"
      isOpen={isOpen}
      {...props}
      shouldFocusAfterRender={false}
    >
      <div className="flex flex-col items-center justify-center w-full h-full bg-black_900_7f ">
        <HashLoader loading={isOpen} color="white" />
        <div className="mt-6 font-semibold text-white">{children}</div>
      </div>
    </ModalProvider>
  );
};

export default LoaderModal;
