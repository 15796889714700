import React, { useEffect, useRef, useState } from "react";
import { default as ModalProvider } from "react-modal";
import { Button, CheckBox, Img, Input, Line, Text } from "../../components";
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
  or,
  and,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const SearchModal = (props) => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  async function handleSearch() {
    if (search === "") return;
    const searchLower = search?.toLowerCase();
    const q = query(
      collection(getFirestore(), "Products"),
      and(
        or(
          where("name", "==", search),
          where("name", "==", searchLower),
          where("tags", "array-contains", search),
          where("tags", "array-contains", searchLower),
          where("colors", "array-contains", search),
          where("colors", "array-contains", searchLower),
          where("subCategory", "==", search),
          where("subCategory", "==", searchLower),
          where("brandName", "==", search),
          where("brandName", "==", searchLower)
        ),
        where("isVisible", "==", true)
      )
    );
    let data = [];

    await getDocs(q).then((docs) => {
      docs.forEach((doc) => {
        data.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      props.setOpen(false);
      navigate(`/search-results`, {
        state: {
          products: data,
          title: search,
        },
      });
    });
  }

  return (
    <ModalProvider
      appElement={document.getElementById("root")}
      className="w-[100vw] bg-white max-h-40 md:max-h-56"
      overlayClassName=" bg-black_900_7f z-20 fixed h-[100vh] overflow-y-hidden flex  inset-y-[0] w-full"
      {...props}
      onAfterOpen={() => {
        let doc = document.getElementById("root");
        doc.style.height = "100vh";
        doc.style.overflowY = "hidden";
      }}
      onAfterClose={() => {
        document.getElementById("root").style.overflowY = "scroll";
      }}
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        props.setOpen(false);
      }}
    >
      <div className="w-[100vw]">
        <div className="relative flex justify-center text-2xl font-semibold ">
          <Text className="flex flex-row items-center justify-center cursor-pointer">
            <Img
              src="images/IndilistLogo.jpeg"
              className="h-20 md:h-24 lg:h-28 aspect-square"
            />
            <Text
              className="absolute z-10 cursor-pointer right-6 sm:right-12 md:right-14 lg:right-20"
              onClick={() => props.setOpen(false)}
            >
              X
            </Text>
          </Text>
        </div>
        <div className="flex justify-center px-6 mt-1 space-x-1 align-middle sm:mt-2">
          <div className="border border-black h-9 sm:h-11 lg:h-14 w-full sm:w-[500px] md:w-[640px] border-s">
            <input
              type="text"
              id="search-box"
              placeholder="Search Product"
              className=" px-2 md:px-8 sm:h-[38px] h-[85%] lg:h-[50px] w-[75%]"
              onChange={(e) => setSearch(e.target.value)}
              autoFocus
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
            <button
              className=" text-sm md:text-xl w-[25%] font-semibold border-none text-center rounded-none h-full font-inter bg-gray_700_01 text-white_A700"
              onClick={() => handleSearch()}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </ModalProvider>
  );
};

export default SearchModal;
