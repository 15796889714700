import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./style.css";

const TermsCondition = () => {
  return (
    <div className="flex flex-col justify-between min-h-[100dvh] text-left">
      <Header />
      <div className="my-6 sm:my-10 md:my-14 xl:my-20">
        <div
          className=" px-4 sm:px-6  md:w-[80dvw] mx-auto text-left grid gap-3"
          id="buyer-terms-&-conditions"
        >
          <div>
            <h1 className="text-xl font-semibold cursor-pointer sm:text-2xl md:text-4xl">
              Welcome to Indilist,
            </h1>
            <em>
              your premier destination for discovering individual brands,
              products in India.{" "}
            </em>
          </div>

          <div>
            <h2>Buyer Terms and Conditions</h2>
            <em>
              By using our platform as a buyer, you agree to the following terms
              and conditions:
            </em>
          </div>

          <div>
            <h3> * Product Information and Accuracy</h3>
            <p>
              1.1. IndiList acts solely as a marketplace for sellers to list
              their products. We do not independently verify the accuracy or
              legality of the products listed on our platform. Buyers are
              encouraged to review product details, including descriptions,
              images, and specifications, before making a purchase.
            </p>
          </div>
          <div>
            <h3>* Ordering and Payment</h3>
            <p>
              2.1. By placing an order on IndiList, you agree to pay the full
              amount specified for the products, including any applicable taxes,
              shipping fees, and other charges. <br /> 2.2. Payment processing
              and security are handled by trusted third-party payment providers.
              IndiList does not store or process payment information directly.{" "}
              <br /> 2.3. Orders may be subject to verification and acceptance
              by the seller. In the event of order cancellation or rejection,
              any payment made will be refunded in accordance with our refund
              policy.
            </p>
          </div>
          <div id="shipping-&-delivery">
            <h3>* Shipping and Delivery</h3>
            <p>
              3.1. Sellers are responsible for shipping products promptly and
              providing valid tracking information. <br /> 3.2. Estimated
              delivery times are provided by sellers and are subject to factors
              beyond IndiList's control. We are not liable for delays or
              failures in delivery.
            </p>
          </div>
          <div>
            <h3>* Returns and Refunds</h3>
            <p>
              4.1. Buyers may be entitled to return products in accordance with
              the seller's stated return policy. <br />
              4.2. Refunds will be processed based on the seller's refund policy
              and may be subject to deductions for return shipping costs or
              restocking fees, as applicable.
            </p>
          </div>
          <div>
            <h3>* Dispute Resolution</h3>
            <p>
              5.1. In the event of disputes between buyers and sellers, IndiList
              will endeavor to facilitate a resolution based on the information
              provided by both parties. <br /> 5.2. IndiList reserves the right
              to make final decisions regarding disputes, including issuing
              refunds or taking other appropriate actions to resolve the matter.
            </p>
          </div>
          <div>
            <h3>* Account Termination</h3>
            <p>
              6.1. IndiList may suspend or terminate buyer accounts in cases of
              suspected fraudulent activity, violation of these terms and
              conditions, or for any other reason deemed necessary to protect
              the integrity of our platform.
            </p>
          </div>
          <p className="font-semibold text-center text-gray_800_01 ">
            ****By using IndiList as a buyer, you acknowledge that you have
            read, understood, and agree to abide by these terms and
            conditions.****
          </p>
        </div>
        <div
          className=" px-4 sm:px-6  md:w-[80dvw] mx-auto text-left grid gap-3 mt-6"
          id="seller-terms-&-conditions"
        >
          <h2>Seller Terms and Conditions</h2>
          <div>
            <h3>1. Introduction</h3>
            <p>
              Welcome to IndiList, a platform dedicated to empowering creators
              and small brands in India. By onboarding as a seller on IndiList,
              you agree to the following terms and conditions.
            </p>
          </div>
          <div>
            <h3>2. Product Listings and Ownership</h3>
            <p>
              2.1. Sellers are responsible for the accuracy and legality of the
              products they list on IndiList. <br />
              2.2. Sellers must either produce their products in-house or design
              them in-house and get production outsourced. The brand must belong
              to the seller, and the production process should adhere to legal
              and ethical standards. <br />
              2.3. Sellers must only list products they have the right to sell,
              and the brand must belong to the seller.
            </p>
          </div>
          <div>
            <h3>3. Shipping and Returns</h3>
            <p>
              3.1. Sellers are responsible for shipping their products to
              customers promptly. <br />
              3.2. Sellers who choose to accept returns are required to bear the
              return fees incurred by customers. <br />
              3.3. Sellers must provide a valid tracking number for each shipped
              product. <br />
              3.4. Buyers have the right to cancel their order before the
              product is dispatched.
            </p>
          </div>
          <div>
            <h3>4. Seller's Content</h3>
            <p>
              4.1. Sellers are solely responsible for the content they post on
              IndiList, including product descriptions, images, and any other
              information. <br />
              4.2. IndiList reserves the right to remove any content that
              violates the terms and conditions.
            </p>
          </div>
          <div>
            <h3>5. Returns and Refunds</h3>
            <p>
              5.1. Sellers who choose to accept returns must clearly outline
              their return policy on their product listings. <br />
              5.2. Even if a seller chooses not to accept returns, IndiList
              reserves the right to facilitate returns for products that are
              damaged or not as described. In such cases, the seller will bear
              the return cost. <br />
              5.3. Refunds will be processed in accordance with the seller's
              stated return policy.
            </p>
          </div>
          <div>
            <h3>6. Liability and Indemnification</h3>
            <p>
              6.1. IndiList is not responsible for the actions or products of
              individual sellers on the platform. <br />
              6.2. Sellers agree to indemnify and hold IndiList harmless from
              any claims, losses, or damages arising from their activities on
              the platform.
            </p>
          </div>
          <div>
            <h3>7. Termination of Account</h3>
            <p>
              7.1. IndiList reserves the right to terminate the account of any
              seller violating these terms and conditions.
            </p>
          </div>
          <div>
            <h3>8. Changes to Terms and Conditions</h3>
            <p>
              8.1. IndiList reserves the right to modify these terms and
              conditions at any time. Sellers will be notified of any changes.
            </p>
          </div>
          <div>
            <h3>9. Governing Law</h3>
            <p>
              9.1. These terms and conditions are governed by the laws of India.
            </p>
          </div>
          <div>
            <h3> 10. Dispute Resolution</h3>
            <p>
              10.1. In the event of disputes between buyers and sellers,
              IndiList will act as the deciding authority. Sellers agree to
              comply with the decisions made by IndiList.
            </p>
          </div>
          <div>
            <h3> 11. Commission</h3>
            <p>11.1. We charge as little as 3% commission on all our sales.</p>
          </div>
          <div>
            <p className="font-semibold text-center text-gray_800_01 ">
              ****By onboarding as a seller on IndiList, you acknowledge that
              you have read, understood, and agree to abide by these terms and
              conditions.****
            </p>
          </div>
        </div>
      </div>
      <Footer className="flex items-center justify-center w-full mt-auto bg-gray_700 md:px-5" />
    </div>
  );
};

export default TermsCondition;
