import React from "react";
import { HashLoader } from "react-spinners";

const PageLoader = () => {
  return (
    <>
      <div className="fixed top-0 z-50 flex flex-col items-center justify-center h-full w-full bg-[rgba(0,0,0,0.8)] ">
        <HashLoader loading={true} color="white" />
      </div>
    </>
  );
};

export default PageLoader;
