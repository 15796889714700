import "./App.css";
import { Suspense, lazy, useState } from "react";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { Routes, Route, useNavigate } from "react-router-dom";
import {
  getFirestore,
  query,
  where,
  collection,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";

import ProtectedRoutes from "./utils/ProtectedRoutes";
import SellerRoutes from "./utils/SellerRoutes";
import SignInPage from "./SellersSrc/src/pages/SignIn";
import SignUpPage from "./SellersSrc/src/pages/SignUp";
import ForgotPasswordPage from "./SellersSrc/src/pages/ForgotPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorPage from "./pages/Error";
import { useEffect } from "react";
import { ProSidebarProvider } from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  browserLocalPersistence,
  getAuth,
  onAuthStateChanged,
  setPersistence,
} from "firebase/auth";
import {
  setDeliveryAddresses,
  setSelectedAddress,
} from "./store/slices/cartSlice";
import AboutUs from "./pages/AboutUs";
import TermsCondition from "./pages/TermsConditionn";
import BuyersRoute from "./utils/BuyersRoute";
import PageLoader from "./components/PageLoader";
import { logOutUser, setSellerProfile } from "./store/slices/userSlice";
const Home = lazy(() => import("./pages/HomeOne/index"));
const ShopByCategory = lazy(() => import("./pages/CategoryShopbyCategory"));
const DealsoftheDay = lazy(() => import("./pages/DealsoftheDay"));
const ProductPage = lazy(() => import("./pages/Productpage"));
const CartPage = lazy(() => import("./pages/Cart"));
const CheckOut = lazy(() => import("./pages/Checkout"));
const WishList = lazy(() => import("./pages/Mywishlist"));
const Home1Page = lazy(() => import("./SellersSrc/src/pages/Home1"));
const MyAccount = lazy(() => import("./pages/Myaccount"));
const SettingsMyPaymentsPage = lazy(() =>
  import("./SellersSrc/src/pages/SettingsMyPayments")
);
const SettingsStoreControlPage = lazy(() =>
  import("./SellersSrc/src/pages/SettingsStoreControl")
);
const SettingsEditProfilePage = lazy(() =>
  import("./SellersSrc/src/pages/SettingsEditProfile")
);
const SellerProductPage = lazy(() => import("./SellersSrc/src/pages/Products"));
const AddProductsPage = lazy(() =>
  import("./SellersSrc/src/pages/AddProducts")
);
const SettingsOne = lazy(() => import("./SellersSrc/src/pages/SettingsOne"));
const SellerspagePage = lazy(() => import("./pages/Sellerspage"));
const AnalyticsPage = lazy(() => import("./SellersSrc/src/pages/Analytics"));
const SellerOnBoard = lazy(() => import("./SellersSrc/src/pages/SellerOnBoad"));

// import { useState } from "react";
// import CloudinaryUploadWidget from "./CloudinaryUploadWidget";
// import { Cloudinary } from "@cloudinary/url-gen";
// import { AdvancedImage, responsive, placeholder } from "@cloudinary/react";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDL0SZaTKB3elOn61Qj4J73c2PViLfk53k",
  authDomain: "wavezup.firebaseapp.com",
  projectId: "wavezup",
  storageBucket: "wavezup.appspot.com",
  messagingSenderId: "812767652010",
  appId: "1:812767652010:web:a47da3687cee13816488d3",
  measurementId: "G-KHVEP06GJE",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// const db = getFirestore(app);

// const [publicId, setPublicId] = useState("");
// // Replace with your own cloud name
// const [cloudName] = useState("dyvbktirn");
// // Replace with your own upload preset
// const [uploadPreset] = useState("wwndi49j");

// Upload Widget Configuration
// Remove the comments from the code below to add
// additional functionality.
// Note that these are only a few examples, to see
// the full list of possible parameters that you
// can add see:
//   https://cloudinary.com/documentation/upload_widget_reference

// const [uwConfig] = useState({
//   cloudName,
//   uploadPreset,
//   // cropping: true, //add a cropping step
//   // showAdvancedOptions: true,  //add advanced options (public_id and tag)
//   // sources: [ "local", "url"], // restrict the upload sources to URL and local files
//   // multiple: false,  //restrict upload to a single file
//   // folder: "user_images", //upload files to the specified folder
//   // tags: ["users", "profile"], //add the given tags to the uploaded files
//   // context: {alt: "user_uploaded"}, //add the given context data to the uploaded files
//   // clientAllowedFormats: ["images"], //restrict uploading to image files only
//   // maxImageFileSize: 2000000,  //restrict file size to less than 2MB
//   // maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
//   // theme: "purple", //change to a purple theme
// });

// Create a Cloudinary instance and set your cloud name.
// const cld = new Cloudinary({
//   cloud: {
//     cloudName,
//   },
// });

// const myImage = cld.image(publicId);

{
  /* <AdvancedImage
style={{ maxWidth: "100%" }}
cldImg={myImage}
plugins={[responsive(), placeholder()]}
/> */
}

function App() {
  const auth = getAuth();
  const dispatch = useDispatch();
  const user = useSelector((state) => {
    return state?.user?.user;
  });
  const selectedAddress = useSelector((state) => {
    return state.cart?.selectedAddress;
  });
  const [firebaseAuthUser, setFirebaseAuthUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        // User is signed in, check if token needs refreshing
        const tokenExpirationTime =
          firebaseUser.metadata.lastSignInTime + 3600 * 1000; // Example: token expires 1 hour after sign-in
        const timeUntilExpiration = tokenExpirationTime - Date.now();
        if (timeUntilExpiration < 5 * 60 * 1000) {
          // Refresh token if it expires in less than 5 minutes
          try {
            await firebaseUser.getIdToken(true);
            console.log("Token refreshed");
          } catch (error) {
            console.error("Error refreshing token:", error.message);
          }
        }
        setFirebaseAuthUser(firebaseUser);
      } else {
        // No user is signed in

        dispatch(logOutUser());
        setFirebaseAuthUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);
  useEffect(() => {
    if (firebaseAuthUser) {
      // Set persistence to LOCAL
      // This will persist the user's authentication state across sessions
      // and prevent re-authentication when the tab is closed and reopened.
      // You can adjust the persistence option based on your requirements.
      setPersistence(auth, browserLocalPersistence)
        .then(() => {
          console.log("Session persistence enabled");
        })
        .catch((error) => {
          console.error("Error enabling session persistence:", error);
        });
    }
  }, [firebaseAuthUser]);

  const fetchUserData = async () => {
    await auth.authStateReady();

    if (!auth.currentUser) {
      return;
    }
    if (auth.currentUser) {
      const queryRef = query(
        collection(getFirestore(), "Addresses"),
        where("userId", "==", auth.currentUser.uid)
      );

      // Fetch the matching documents
      await getDocs(queryRef)
        .then((querySnapshot) => {
          let addresses = [];
          querySnapshot.forEach((doc) => {
            addresses.push({ ...doc.data(), id: doc.id });
          });
          dispatch(setDeliveryAddresses(addresses));
          // if (Object.keys(selectedAddress).length == 0) {
          dispatch(setSelectedAddress(addresses[0]));
          // }
        })
        .catch((error) => {
          console.error("Error fetching documents:", error);
        });

      await getDoc(
        doc(collection(getFirestore(), "Sellers"), auth.currentUser.uid)
      ).then((doc) => {
        if (doc.exists) {
          dispatch(setSellerProfile({ ...doc.data(), id: doc.id }));
        }
      });
    }
  };
  useEffect(() => {
    fetchUserData();
  }, [user]);
  return (
    <div className="App">
      <ToastContainer />
      <Suspense fallback={<PageLoader />}>
        <ProSidebarProvider>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/seller-signin" element={<SignInPage />} />
            <Route path="/seller-signup" element={<SignUpPage />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/terms-&-conditions" element={<TermsCondition />} />
            <Route path="/seller-onboarding" element={<SellerOnBoard />} />
            <Route element={<BuyersRoute />}>
              <Route path="/products" element={<ProductPage />} />
              <Route path="/dealsoftheday" element={<DealsoftheDay />} />
              <Route path="/search-results" element={<DealsoftheDay />} />
              <Route path="/shopbycategory" element={<ShopByCategory />} />
              <Route path="/sellers-page" element={<SellerspagePage />} />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/wishlist" element={<WishList />} />
            </Route>
            <Route element={<ProtectedRoutes />}>
              <Route path="/myaccount" element={<MyAccount />} />
              <Route path="/checkout" element={<CheckOut />} />
            </Route>

            <Route element={<SellerRoutes />}>
              <Route path="/seller-home" element={<Home1Page />} />
              <Route path="/seller-products" element={<SellerProductPage />} />
              <Route path="/edit-products" element={<AddProductsPage />} />
              <Route path="/analytics" element={<AnalyticsPage />} />
              <Route path="/seller-settings" element={<SettingsOne />} />
              <Route
                path="/settings-edit"
                element={<SettingsEditProfilePage />}
              />
              <Route
                path="/settings-store"
                element={<SettingsStoreControlPage />}
              />
              <Route
                path="/settings-payments"
                element={<SettingsMyPaymentsPage />}
              />
            </Route>
            <Route path="/not-found" element={<ErrorPage />} />
          </Routes>
        </ProSidebarProvider>
      </Suspense>
    </div>
  );
}

export default App;
