import React from "react";
const HeartIcon = ({ fillColor }) => {
  return (
    <>
      <svg
        width="30"
        height="26"
        viewBox="0 0 30 26"
        fill={fillColor}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.0497 3.76234C25.4112 3.1236 24.6532 2.6169 23.8189 2.2712C22.9845 1.92549 22.0903 1.74756 21.1872 1.74756C20.2841 1.74756 19.3898 1.92549 18.5555 2.2712C17.7211 2.6169 16.9631 3.1236 16.3247 3.76234L14.9997 5.08734L13.6747 3.76234C12.385 2.47273 10.636 1.74823 8.81216 1.74823C6.98837 1.74823 5.23928 2.47273 3.94966 3.76234C2.66005 5.05196 1.93555 6.80105 1.93555 8.62484C1.93555 10.4486 2.66005 12.1977 3.94966 13.4873L5.27466 14.8123L14.9997 24.5373L24.7247 14.8123L26.0497 13.4873C26.6884 12.8489 27.1951 12.0909 27.5408 11.2565C27.8865 10.4222 28.0644 9.52795 28.0644 8.62484C28.0644 7.72173 27.8865 6.82747 27.5408 5.99315C27.1951 5.15882 26.6884 4.40079 26.0497 3.76234Z"
          stroke="#747474"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
export { HeartIcon };
