import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar1 from "../SellersSrc/src/components/Sidebar1";
import Header1 from "../components/Header1";
import { useSelector } from "react-redux";

const SellerRoutes = () => {
  const user = useSelector((state) => {
    return state?.user?.user;
  });
  const seller = useSelector((state) => {
    return state.user?.sellerProfile;
  });

  if (user == null) {
    return <Navigate to="/seller-signin" replace={true} />;
  }

  if (!seller || !seller.isApproved) {
    return <Navigate to="/seller-onboarding" replace={true} />;
  }

  return (
    <>
      <Header1 />
      <div className="relative flex flex-row items-start w-full gap-0 ">
        {/* <LoaderModal isOpen={loading} /> */}

        <Sidebar1 className="!sticky  px-0 mx-0 flex  pb-10 h-auto bg-gray_700 min-h-dvh  justify-start  top-[0]" />

        <Outlet />
      </div>
    </>
  );
};

export default SellerRoutes;
