import React, { useState } from "react";
import { default as ModalProvider } from "react-modal";
import { Button, CheckBox, Img, Input, Line, Text } from "../../components";
import { getAuth, signOut } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { logOutUser } from "../../store/slices/userSlice";
import { toast } from "react-toastify";

const MyAccountModal = ({ isOpen, setOpen, ...props }) => {
  const auth = getAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isLoggedIn } = useSelector((state) => {
    return state.user;
  });

  return (
    <ModalProvider
      appElement={document.getElementById("root")}
      className="sm:!w-[400px] m-auto w-[90vw]"
      overlayClassName=" z-20 bg-black_900_7f fixed h-[100vh] overflow-y-hidden flex  inset-y-[0] w-full"
      {...props}
      isOpen={isOpen}
      onAfterOpen={() => {
        let doc = document.getElementById("root");
        doc.style.height = "100vh";
        doc.style.overflowY = "hidden";
      }}
      onAfterClose={() => {
        document.getElementById("root").style.overflowY = "scroll";
      }}
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        // props.setOpen(false);
        setOpen(false);
      }}
    >
      <div className="w-full max-h-[80vh]  bg-white_A700 text-gray_700 rounded-xl relative overflow-y-auto p-6 ">
        <Text
          className="absolute z-20 text-xl cursor-pointer top-6 right-6"
          onClick={() => setOpen(false)}
        >
          X
        </Text>
        <div className="px-8 font-inter">
          <Text className="w-full text-lg font-semibold text-center ">
            Welcome, {user?.displayName.split(" ")[0]}
          </Text>
          <Line className="w-full h-[1px] bg-gray_700_cc_01 mt-3" />
          <div className="grid gap-6 my-6 font-medium ">
            <Text
              onClick={() => {
                setOpen(false);
                navigate("/myaccount", { state: 0 });
              }}
              className="cursor-pointer "
            >
              Manage My Account
            </Text>
            <Text
              onClick={() => {
                setOpen(false);
                navigate("/myaccount", { state: 1 });
              }}
              className="cursor-pointer "
            >
              My Orders
            </Text>
            <Text
              onClick={() => {
                setOpen(false);
                navigate("/myaccount", { state: 2 });
              }}
              className="cursor-pointer "
            >
              Address Book
            </Text>
            <Text
              onClick={() => {
                setOpen(false);
                navigate("/seller-home");
              }}
              className="cursor-pointer "
            >
              Sell on Indilist
            </Text>
          </div>
          <Line className="w-full h-[1px] bg-gray_700_cc_01 mt-3" />
          <Button
            variant="outline"
            color="gray_700_01"
            size="sm"
            className="w-full mt-6 text-xl font-medium font-inter"
            onClick={() => {
              signOut(auth)
                .then(() => {
                  localStorage.removeItem("user");
                  localStorage.removeItem("currentStep");
                  localStorage.removeItem("newSellerData");
                  dispatch(logOutUser());
                  toast.success("Logged out !");
                  setOpen(false);
                })
                .catch((error) => {
                  console.log("error", error);
                  window.location.reload();
                });
            }}
          >
            Sign Out
          </Button>
        </div>
      </div>
    </ModalProvider>
  );
};

export default MyAccountModal;
