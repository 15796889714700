import React from "react";

const sizeClasses = {
  txtInterRegular2441: "font-inter font-normal",
  txtInterMedium22: "font-inter font-medium",
  txtInterMedium1512: "font-inter font-medium",
  txtInterMedium3612: "font-inter font-medium",
  txtInterRegular1835: "font-inter font-normal",
  txtInterMedium30: "font-inter font-medium",
  txtInterRegular1677: "font-inter font-normal",
  txtInterRegular1712: "font-inter font-normal",
  txtInterRegular1635: "font-inter font-normal",
  txtInterRegular16GreenA700: "font-inter font-normal",
  txtInterRegular1112: "font-inter font-normal",
  txtInterRegular1432: "font-inter font-normal",
  txtInterRegular1262: "font-inter font-normal",
  txtInterMedium18: "font-inter font-medium",
  txtInterRegular1461: "font-inter font-normal",
  txtInterRegular2073: "font-inter font-normal",
  txtInterMedium3012: "font-inter font-medium",
  txtInterRegular1262Gray70001: "font-inter font-normal",
  txtInterMedium16: "font-inter font-medium",
  txtInterMedium15: "font-inter font-medium",
  txtInterMedium2235WhiteA700: "font-inter font-medium",
  txtPoppinsMedium24: "font-medium font-poppins",
  txtInterRegular2757Red500: "font-inter font-normal",
  txtInterSemiBold40: "font-inter font-semibold",
  txtInterMedium3404: "font-inter font-medium",
  txtPoppinsRegular23: "font-normal font-poppins",
  txtInterMedium2435: "font-inter font-medium",
  txtInterMedium1269: "font-inter font-medium",
  txtInterMedium2235: "font-inter font-medium",
  txtInterMedium2112: "font-inter font-medium",
  txtInterRegular1269: "font-inter font-normal",
  txtInterRegular13: "font-inter font-normal",
  txtInterRegular1262Red800: "font-inter font-normal",
  txtInterRegular2757: "font-inter font-normal",
  txtInterRegular14: "font-inter font-normal",
  txtInterMedium1747: "font-inter font-medium",
  txtInterRegular15: "font-inter font-normal",
  txtInterRegular16: "font-inter font-normal",
  txtInterRegular2035: "font-inter font-normal",
  txtInterMedium1229: "font-inter font-medium",
  txtInterRegular18: "font-inter font-normal",
  txtInterMedium2235Red500: "font-inter font-medium",
  txtInterRegular916: "font-inter font-normal",
  txtInterRegular239: "font-inter font-normal",
  txtInterRegular835: "font-inter font-normal",
  txtInterMedium2235Green600: "font-inter font-medium",
  txtInterRegular16Red500: "font-inter font-normal",
  txtInterRegular1835Gray7007f: "font-inter font-normal",
  txtInterMedium3312: "font-inter font-medium",
  txtInterMedium3235: "font-inter font-medium",
  txtInterRegular1935: "font-inter font-normal",
  txtInterMedium3035: "font-inter font-medium",
  txtInterRegular1135: "font-inter font-normal",
  txtInterRegular1135WhiteA700: "font-inter font-normal",
  txtInterRegular2694: "font-inter font-normal",
  txtInterRegular2051: "font-inter font-normal",
  txtInterMedium2335: "font-inter font-medium",
  txtInterMedium2412: "font-inter font-medium",
  txtInterMedium2017: "font-inter font-medium",
  txtInterMedium2212: "font-inter font-medium",
  txtInterMedium18Gray70001: "font-inter font-medium",
  txtInterRegular13Gray600: "font-inter font-normal",
  txtInterRegular13Red800: "font-inter font-normal",
  txtInterMedium1607: "font-inter font-medium",
  txtInterRegular2135: "font-inter font-normal",
  txtInterMedium9: "font-inter font-medium",
};

const Text = ({ children, className = "", size, as, ...restProps }) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
