import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const AboutUs = () => {
  return (
    <div className="flex flex-col justify-between w-full min-h-[100dvh]">
      <Header />
      <div className="my-6 sm:my-10 md:my-14 xl:my-20">
        <div className=" px-4 sm:px-6  md:w-[80dvw] mx-auto text-left">
          <h1 className="text-xl font-semibold cursor-pointer sm:text-2xl md:text-4xl">
            Welcome to Indilist,
          </h1>
          <div className="flex flex-col px-2 text-sm sm:text-base md:text-lg">
            <em className="text-xs sm:text-sm md:text-base">
              your premier destination for discovering a diverse array of
              products straight from the heart of India.
            </em>
            <p className="mt-2 ">
              Indilist is not just a marketplace; it's a vibrant community where
              independent sellers thrive, offering a wide range of products
              spanning various categories such as clothing, skincare, bath and
              body essentials, baby products, home decor, and art and
              collectibles.
              <br /> <br /> Our platform champions the spirit of
              entrepreneurship, providing a space where makers and designers can
              showcase their creations without the constraints of competing
              against large brands or resellers. Whether you're a seller looking
              to share your unique creations or a shopper in search of something
              special, Indilist is your go-to destination. <br /> <br /> At
              Indilist, we celebrate diversity, creativity, and authenticity.
              Every product you find here tells a story of passion,
              craftsmanship, and innovation, reflecting the rich cultural
              tapestry of India. Thank you for being a part of our community!
            </p>
          </div>
        </div>
      </div>
      <Footer className="flex items-center justify-center w-full mt-auto bg-gray_700 md:px-5" />
    </div>
  );
};

export default AboutUs;
