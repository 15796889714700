import React, { useState } from "react";
import { default as ModalProvider } from "react-modal";
import {
  Button,
  CheckBox,
  Img,
  Input,
  Line,
  SelectBox,
  Text,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import CheckOutAddressCard from "../../components/cards/address/CheckOutAddressCard";
import { useNavigate } from "react-router-dom";
import { setSelectedAddress } from "../../store/slices/cartSlice";

const SelectAddressModal = (props) => {
  const deliveryAddress = useSelector((state) => {
    return state?.cart?.deliveryAddress;
  });
  const selectedAddress = useSelector((state) => {
    return state?.cart?.selectedAddress;
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <ModalProvider
      appElement={document.getElementById("root")}
      className="w-[90vw] sm:!w-[534px]  m-auto"
      overlayClassName=" bg-black_900_7f z-20 fixed h-[100vh] overflow-y-hidden flex  inset-y-[0] w-full"
      {...props}
      onAfterOpen={() => {
        let doc = document.getElementById("root");
        doc.style.height = "100vh";
        doc.style.overflowY = "hidden";
      }}
      onAfterClose={() => {
        document.getElementById("root").style.overflowY = "scroll";
      }}
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        props.setOpen(false);
      }}
    >
      <div className="relative w-full overflow-y-auto rounded-xl bg-white_A700 text-gray_700">
        <Text
          className="absolute z-10 text-xl cursor-pointer top-6 right-6"
          onClick={() => props.setOpen(false)}
        >
          X
        </Text>
        <div className="pt-6 font-inter">
          <Text className="w-full text-xs font-medium text-center sm:text-lg ">
            CHOOSE YOUR LOCATION
          </Text>
          <Line className="w-full h-[1px] bg-gray_700_cc_01 mt-3" />

          <div className="grid gap-3 p-8 sm:gap-4">
            <Text className="text-xs font-medium sm:text-lg ">
              Select Your Location
            </Text>
            {deliveryAddress?.map((item, index) => {
              return (
                <CheckOutAddressCard item={item} key={index}>
                  <div
                    className={`border border-gray_700 border-solid flex flex-col justify-center h-3 sm:h-6 items-center   rounded-[50%] aspect-square  `}
                    onClick={() => dispatch(setSelectedAddress(item))}
                  >
                    <div
                      className={` h-1 w-1 sm:h-2.5 rounded-[50%] sm:w-2.5 ${
                        item.id === selectedAddress?.id
                          ? "bg-gray_700"
                          : "bg-white"
                      }`}
                    ></div>
                  </div>
                </CheckOutAddressCard>
              );
            })}

            <div className="flex flex-row justify-end ">
              <Button
                className="cursor-pointer flex justify-center items-center font-medium h-7 w-7 sm:h-[36px] sm:w-[36px] md:h-[50px] leading-[normal] sm:text-[23.85px] md:text-[25.85px]  text-center md:w-[50px]"
                shape="square"
                color="gray_700"
                size="sm"
                variant="outline"
                onClick={() => {
                  navigate("/myaccount", { state: 2 });
                  props.setOpen(false);
                }}
              >
                +
              </Button>
            </div>
            {/* <Button
              className="flex justify-center w-full space-x-6 "
              variant="outline"
              color="gray_700"
              size="md"
            >
              <Img
                className="h-3 w-3 sm:h-6 mt-0.5 sm:w-6"
                src="images/img_save.svg"
                alt="save"
              />
              <Text className="text-xs sm:text-lg text-gray_700">
                Use Your Current Location
              </Text>
            </Button> */}
          </div>
        </div>
      </div>
    </ModalProvider>
  );
};

export default SelectAddressModal;
