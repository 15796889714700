import React from "react";

const sizeClasses = {
  txtInterRegular2756Gray70001: "font-inter font-normal",
  txtInterSemiBold1261: "font-inter font-semibold",
  txtInterRegular20: "font-inter font-normal",
  txtInterMedium2099: "font-inter font-medium",
  txtInterSemiBold234: "font-inter font-semibold",
  txtInterRegular2104Gray70001: "font-inter font-normal",
  txtInterMedium3061: "font-inter font-medium",
  txtInterMedium1952: "font-inter font-medium",
  txtInterMedium1678: "font-inter font-medium",
  txtInterMedium3612: "font-inter font-medium",
  txtInterMedium1261WhiteA700: "font-inter font-medium",
  txtInterMedium1731Indigo900: "font-inter font-medium",
  txtInterRegular1835: "font-inter font-normal",
  txtInterMedium3335: "font-inter font-medium",
  txtInterMedium1671: "font-inter font-medium",
  txtInterMedium22Black900: "font-inter font-medium",
  txtInterRegular1314: "font-inter font-normal",
  txtInterRegular1677: "font-inter font-normal",
  txtInterRegular22: "font-inter font-normal",
  txtInterRegular1712: "font-inter font-normal",
  txtInterRegular1794: "font-inter font-normal",
  txtInterRegular1155: "font-inter font-normal",
  txtInterRegular1432: "font-inter font-normal",
  txtInterRegular1262: "font-inter font-normal",
  txtInterRegular1261: "font-inter font-normal",
  txtInterRegular1781: "font-inter font-normal",
  txtInterSemiBold2361: "font-inter font-semibold",
  txtInterRegular2073: "font-inter font-normal",
  txtInterRegular1262Gray70001: "font-inter font-normal",
  txtInterMedium2085: "font-inter font-medium",
  txtInterMedium2361: "font-inter font-medium",
  txtInterRegular19WhiteA700: "font-inter font-normal",
  txtInterRegular19: "font-inter font-normal",
  txtInterSemiBold40: "font-inter font-semibold",
  txtPoppinsRegular23: "font-normal font-poppins",
  txtInterMedium1269: "font-inter font-medium",
  txtInterMedium1783: "font-inter font-medium",
  txtInterRegular1823: "font-inter font-normal",
  txtInterMedium2235: "font-inter font-medium",
  txtInterMedium1635Gray70001: "font-inter font-medium",
  txtInterMedium1661: "font-inter font-medium",
  txtInterMedium2112: "font-inter font-medium",
  txtInterRegular1269: "font-inter font-normal",
  txtInterRegular1149: "font-inter font-normal",
  txtInterRegular13: "font-inter font-normal",
  txtInterRegular2757: "font-inter font-normal",
  txtInterRegular14: "font-inter font-normal",
  txtInterRegular2756: "font-inter font-normal",
  txtInterRegular1667: "font-inter font-normal",
  txtInterRegular15: "font-inter font-normal",
  txtInterRegular16: "font-inter font-normal",
  txtInterRegular1661: "font-inter font-normal",
  txtInterMedium1547: "font-inter font-medium",
  txtInterRegular17: "font-inter font-normal",
  txtInterRegular18: "font-inter font-normal",
  txtInterRegular2235: "font-inter font-normal",
  txtInterMedium1441Gray700: "font-inter font-medium",
  txtInterRegular916: "font-inter font-normal",
  txtInterMedium1441Gray70001: "font-inter font-medium",
  txtInterRegular1494: "font-inter font-normal",
  txtInterRegular239: "font-inter font-normal",
  txtInterRegular20WhiteA700: "font-inter font-normal",
  txtInterRegular2104Red500: "font-inter font-normal",
  txtPoppinsRegular1697: "font-normal font-poppins",
  txtInterMedium2788: "font-inter font-medium",
  txtInterMedium1731: "font-inter font-medium",
  txtInterRegular1835Gray7007f: "font-inter font-normal",
  txtInterSemiBold9: "font-inter font-semibold",
  txtInterRegular14WhiteA700: "font-inter font-normal",
  txtInterRegular1935: "font-inter font-normal",
  txtInterMedium246: "font-inter font-medium",
  txtInterRegular2104: "font-inter font-normal",
  txtInterRegular1135: "font-inter font-normal",
  txtInterRegular1894: "font-inter font-normal",
  txtInterRegular1135WhiteA700: "font-inter font-normal",
  txtInterRegular1086: "font-inter font-normal",
  txtInterRegular2694: "font-inter font-normal",
  txtInterRegular1483: "font-inter font-normal",
  txtInterRegular2051: "font-inter font-normal",
  txtInterRegular222: "font-inter font-normal",
  txtInterRegular102: "font-inter font-normal",
  txtInterMedium2412: "font-inter font-medium",
  txtInterMedium1961: "font-inter font-medium",
  txtInterMedium2017: "font-inter font-medium",
  txtInterRegular18Gray70001: "font-inter font-normal",
  txtInterRegular1527: "font-inter font-normal",
  txtInterMedium1561: "font-inter font-medium",
  txtInterMedium18Gray70001: "font-inter font-medium",
  txtInterMedium1441: "font-inter font-medium",
  txtInterRegular13Gray70001: "font-inter font-normal",
  txtInterRegular13Red800: "font-inter font-normal",
  txtInterMedium1848: "font-inter font-medium",
  txtInterMedium1607: "font-inter font-medium",
  txtInterRegular1089: "font-inter font-normal",
  txtInterRegular1485: "font-inter font-normal",
  txtInterMedium1844: "font-inter font-medium",
  txtInterRegular1195: "font-inter font-normal",
  txtInterSemiBold1188: "font-inter font-semibold",
  txtInterRegular2441: "font-inter font-normal",
  txtInterRegular1594: "font-inter font-normal",
  txtInterRegular2044: "font-inter font-normal",
  txtInterMedium3661: "font-inter font-medium",
  txtInterRegular137: "font-inter font-normal",
  txtInterBold1731: "font-bold font-inter",
  txtInterMedium11663: "font-inter font-medium",
  txtInterMedium22: "font-inter font-medium",
  txtInterMedium1512: "font-inter font-medium",
  txtInterMedium1599: "font-inter font-medium",
  txtInterRegular1516: "font-inter font-normal",
  txtInterMedium2444: "font-inter font-medium",
  txtInterMedium2441: "font-inter font-medium",
  txtInterSemiBold1219: "font-inter font-semibold",
  txtInterMedium1595: "font-inter font-medium",
  txtInterRegular2756Red800: "font-inter font-normal",
  txtInterRegular1635: "font-inter font-normal",
  txtInterRegular1117: "font-inter font-normal",
  txtInterMedium3816: "font-inter font-medium",
  txtInterRegular1112: "font-inter font-normal",
  txtInterMedium1635: "font-inter font-medium",
  txtInterMedium1999: "font-inter font-medium",
  txtInterMedium18: "font-inter font-medium",
  txtInterRegular1461: "font-inter font-normal",
  txtInterMedium3012: "font-inter font-medium",
  txtInterMedium16Gray70001: "font-inter font-medium",
  txtInterMedium16: "font-inter font-medium",
  txtInterMedium2161: "font-inter font-medium",
  txtInterMedium15: "font-inter font-medium",
  txtInterMedium4061: "font-inter font-medium",
  txtPoppinsMedium24: "font-medium font-poppins",
  txtInterMedium1985: "font-inter font-medium",
  txtInterMedium3404: "font-inter font-medium",
  txtInterMedium2435: "font-inter font-medium",
  txtInterMedium20: "font-inter font-medium",
  txtInterRegular1867: "font-inter font-normal",
  txtInterMedium2035: "font-inter font-medium",
  txtInterRegular1262Red800: "font-inter font-normal",
  txtInterMedium212: "font-inter font-medium",
  txtInterMedium1508: "font-inter font-medium",
  txtInterMedium1747: "font-inter font-medium",
  txtInterSemiBold2435: "font-inter font-semibold",
  txtInterRegular1188: "font-inter font-normal",
  txtInterRegular2035: "font-inter font-normal",
  txtInterRegular1221: "font-inter font-normal",
  txtInterMedium1229: "font-inter font-medium",
  txtInterMedium1819: "font-inter font-medium",
  txtInterRegular835: "font-inter font-normal",
  txtInterMedium1261: "font-inter font-medium",
  txtInterRegular1712Gray700b2: "font-inter font-normal",
  txtInterMedium1535: "font-inter font-medium",
  txtInterRegular1219: "font-inter font-normal",
  txtInterRegular1736: "font-inter font-normal",
  txtInterMedium2861: "font-inter font-medium",
  txtInterMedium1894: "font-inter font-medium",
  txtInterMedium3312: "font-inter font-medium",
  txtInterMedium1531: "font-inter font-medium",
  txtInterMedium2067: "font-inter font-medium",
  txtInterMedium3035: "font-inter font-medium",
  txtInterRegular16Black900: "font-inter font-normal",
  txtInterRegular1611: "font-inter font-normal",
  txtInterRegular1731: "font-inter font-normal",
  txtInterRegular1335: "font-inter font-normal",
  txtInterRegular1338: "font-inter font-normal",
  txtInterRegular1694: "font-inter font-normal",
  txtInterRegular3116: "font-inter font-normal",
  txtInterMedium1416: "font-inter font-medium",
  txtInterRegular2104Gray700cc: "font-inter font-normal",
  txtInterMedium2063: "font-inter font-medium",
  txtInterMedium2061: "font-inter font-medium",
  txtInterSemiBold16: "font-inter font-semibold",
  txtInterRegular151: "font-inter font-normal",
  txtInterMedium2335: "font-inter font-medium",
  txtInterMedium2212: "font-inter font-medium",
  txtInterRegular13Gray600: "font-inter font-normal",
  txtInterRegular1842: "font-inter font-normal",
  txtInterMedium155: "font-inter font-medium",
  txtInterRegular1835Gray700: "font-inter font-normal",
  txtInterRegular2135: "font-inter font-normal",
  txtInterMedium1441Gray40001: "font-inter font-medium",
  txtInterRegular1441: "font-inter font-normal",
  txtInterRegular1961: "font-inter font-normal",
};

const Text = ({ children, className = "", size, as, ...restProps }) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
