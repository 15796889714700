import React, { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const Slider = React.forwardRef(
  (
    {
      className,
      items,
      centerMode,
      magnifiedIndex = 0,
      activeSlideCSS = " scale-75 w-full",
      showThumb = false,
      thumbImages,
      ...props
    },
    ref
  ) => {
    const [mainIndex, setMainIndex] = useState(0);
    const [mainAnimation, setMainAnimation] = useState(false);
    const [thumbIndex, setThumbIndex] = useState(0);
    const [thumbAnimation, setThumbAnimation] = useState(false);

    const isSmall = (index) => {
      if (props?.activeIndex + magnifiedIndex >= items?.length) {
        return index !== props?.activeIndex + magnifiedIndex - items?.length;
      } else {
        return index !== props.activeIndex + magnifiedIndex;
      }
    };

    const slideItems = centerMode
      ? items.map((child, index) => {
          if (isSmall(index)) {
            return React.cloneElement(child, {
              ...child.props,
              className: [child.props?.className, activeSlideCSS]
                .filter(Boolean)
                .join(" "),
            });
          }
          return React.cloneElement(child);
        })
      : items;
    const thumbItems = (thumbImages, [setThumbIndex, setThumbAnimation]) => {
      return thumbImages?.map((item, i) => {
        return (
          <div
            className=" h-[89px] w-[67px] flex flex-col justify-center sm:w-[150px]  sm:h-[200px] m-2 border-white_A700 border-2 sm:border-4 bg-gray_100 p-1"
            onClick={() => (setThumbIndex(i), setThumbAnimation(true))}
            key={i}
          >
            {item}
          </div>
        );
      });
    };

    const [thumbs] = useState(
      thumbItems(thumbImages, [setThumbIndex, setThumbAnimation])
    );

    const slideNext = () => {
      if (!thumbAnimation && thumbIndex < thumbs.length - 1) {
        setThumbAnimation(true);
        setThumbIndex(thumbIndex + 1);
      }
    };

    const slidePrev = () => {
      if (!thumbAnimation && thumbIndex > 0) {
        setThumbAnimation(true);
        setThumbIndex(thumbIndex - 1);
      }
    };
    const syncMainBeforeChange = (e) => {
      setMainAnimation(true);
    };

    const syncMainAfterChange = (e) => {
      setMainAnimation(false);

      if (e.type === "action") {
        setThumbIndex(e.item);
        setThumbAnimation(false);
      } else {
        setMainIndex(thumbIndex);
      }
    };
    const syncThumbs = (e) => {
      setThumbIndex(e.item);
      setThumbAnimation(false);

      if (!mainAnimation) {
        setMainIndex(e.item);
      }
    };

    return (
      <>
        <div className={`${className} relative ${showThumb && "px-6"}`}>
          <AliceCarousel
            activeIndex={mainIndex}
            items={slideItems}
            disableDotsControls
            {...props}
            // disableButtonsControls

            onSlideChange={syncMainBeforeChange}
            onSlideChanged={syncMainAfterChange}
            ref={ref}
          />
          {showThumb && (
            <>
              <div
                className="absolute left-0 top-[49%] cursor-pointer font-bold  z-1 text-gray_600 text-3xl"
                onClick={slidePrev}
              >
                &lang;
              </div>
              <div
                className="absolute top-[49%] right-0 cursor-pointer font-bold z-1 text-gray_600 text-3xl"
                onClick={slideNext}
              >
                &rang;
              </div>
            </>
          )}
        </div>
        {showThumb && (
          <div className="relative px-6 overflow-hidden thumbs">
            <AliceCarousel
              activeIndex={thumbIndex}
              autoWidth
              disableDotsControls
              disableButtonsControls
              items={thumbs}
              mouseTracking={false}
              onSlideChanged={syncThumbs}
              touchTracking={!mainAnimation}
            />
            <div
              className="absolute left-0 top-[49%] cursor-pointer text-gray_600 text-xl font-bold  z-1"
              onClick={slidePrev}
            >
              &lang;
            </div>
            <div
              className="absolute top-[49%] text-gray_600 cursor-pointer text-xl right-0 font-bold z-1"
              onClick={slideNext}
            >
              &rang;
            </div>
          </div>
        )}
      </>
    );
  }
);
export { Slider };
