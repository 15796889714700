import React from "react";

import { Img, Text } from "../";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Header1 = (props) => {
  const navigate = useNavigate();
  const seller = useSelector((state) => {
    return state?.user?.sellerProfile;
  });
  const user = useSelector((state) => {
    return state.user?.user;
  });

  return (
    <>
      <header className={`${props.className}`}>
        <Text
          onClick={() => {
            seller && seller.isApproved
              ? navigate("/seller-home")
              : navigate("/");
          }}
          className="flex flex-row items-center justify-center border-b cursor-pointer"
        >
          <Img
            src="images/IndilistLogo.jpeg"
            className="h-20 md:h-24 lg:h-28 aspect-square"
          />
        </Text>
      </header>
    </>
  );
};

Header1.defaultProps = {};

export default Header1;
