import React, { useState } from "react";

const DropDown = ({
  children,
  className,
  title,
  description,
  subTitle = "",
  isUpper = false,
  border = true,
  iconSize,
  titleClassName = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={`${className} w-full border-solid  ${
        border ? "border-t border-gray_700" : "border-0"
      }  py-6`}
    >
      <div
        className="flex flex-row items-center justify-between w-full text-left cursor-pointer "
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>
          <div
            className={
              titleClassName
                ? titleClassName
                : `${
                    isUpper
                      ? "text-[9px] sm:text-base uppercase"
                      : " text-[13.85px] sm:text-[19.99px]"
                  } my-auto align-middle px-2 font-medium text-left text-gray_700_01 grow shrink basis-auto`
            }
          >
            {title}
          </div>
          {subTitle && (
            <div className="mt-3 text-[12.63px] px-3 sm:text-[18.23px] text-gray_700 uppercase">
              {subTitle}
            </div>
          )}
        </div>
        <div>
          {isOpen ? (
            <img
              loading="lazy"
              src="images/img_arrowup.svg"
              alt="close"
              className={`aspect-square object-contain object-center ${
                iconSize ? `w-[${iconSize}px]` : "w-[19.27px] sm:w-[35px]"
              } shrink-0`}
            />
          ) : (
            <img
              loading="lazy"
              src="images/img_arrowdown_gray_700.svg"
              alt="see more"
              className={`aspect-square object-contain object-center ${
                iconSize ? `w-[${iconSize}px]` : "w-[19.27px] sm:w-[35px]"
              }  shrink-0`}
            />
          )}
        </div>
      </div>

      <div
        className={`relative  ${
          isOpen
            ? " block  pointer-events-auto "
            : " hidden  pointer-events-none"
        }`}
      >
        {description && (
          <div className="mt-2 text-[7.54px] sm:text-[13.7px] leading-4 px-3 text-left text-gray_700_01 max-md:max-w-full">
            {description}
          </div>
        )}
        <div>{children}</div>
      </div>
    </div>
  );
};

export { DropDown };
