import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const ProtectedRoutes = () => {
  const user = useSelector((state) => {
    return state?.user?.user;
  });

  return user == null ? (
    <Navigate to="/" />
  ) : (
    <div>
      <Header />
      <Outlet />
      <Footer className="flex items-center justify-center w-full mt-auto bg-gray_700 md:px-5" />
    </div>
  );
};

export default ProtectedRoutes;
