import React, { useEffect, useRef, useState } from "react";
import { default as ModalProvider } from "react-modal";

import { Button, CheckBox, Img, Input, Line, Text } from "../../components";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { setOpenLogin, setUser } from "../../store/slices/userSlice";
import { toast } from "react-toastify";
import axios from "axios";

const SignupOneModal = () => {
  const BrevoAPIKeys = [
    "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",
    "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",
    "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",
  ];
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => {
    return state.user.openLogin;
  });
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  // const navigate = useNavigate();

  const numberOfDigits = 4;

  const [isNew, setIsNew] = useState(false);
  const [Email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [keepSigned, setKeepSigned] = useState(true);

  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState("");
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [otpLength, setOtpLength] = useState(0);
  const otpBoxReference = useRef([]);
  const [otpSent, setOtpSent] = useState(false);
  const [correctOTP, setCorrectOTP] = useState(0);

  let validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [newUserNameError, setNewUserNameError] = useState("");
  const [newUserEmailError, setNewUserEmailError] = useState("");
  const [newUserPasswordError, setNewUserPasswordError] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [otpError, setOtpError] = useState(false);

  const handleLogin = () => {
    if (!Email.match(validRegex)) {
      setEmailError("Invalid email");
    } else {
      setEmailError("");
      signInWithEmailAndPassword(auth, Email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          console.log("User is logged in", user);
          const { displayName, uid, email, phoneNumber } = user;
          dispatch(
            setUser({
              displayName,
              id: uid,
              email,
              phoneNumber,
              uid: uid,
              isSeller: false,
            })
          );
          dispatch(setOpenLogin(false));
          toast.success("Login successful!");
        })
        .catch((error) => {
          toast.error("Please enter correct details" + error.message);
          const errorCode = error.code;
          const errorMessage = error.message;
        });
    }
  };

  const handleNewUserLogin = () => {
    let err = false;

    if (newUserPassword.length < 6) {
      setNewUserPasswordError("password must be atleat of 6 characters");
      err = true;
    } else {
      setNewUserPasswordError("");
    }
    if (!newUserEmail.match(validRegex)) {
      setNewUserEmailError("Invalid email");
      err = true;
    } else {
      setNewUserEmailError("");
    }
    if (newUserName.length < 3) {
      setNewUserNameError("Name must be more than 3 characters");
      err = true;
    } else {
      setNewUserNameError("");
    }
    if (!(newUserPassword === passwordMatch)) {
      setPasswordMatchError("Password must match");
      err = true;
    } else {
      setPasswordMatchError("");
    }

    if (otp.join("") !== correctOTP.toString()) {
      toast.error("Please enter correct OTP");
      err = true;
    }

    if (!err) {
      console.log(`user is ${newUserEmail} and password ${newUserPassword}`);
      createUserWithEmailAndPassword(auth, newUserEmail, newUserPassword)
        .then((userCredential) => {
          const user = userCredential.user;
          updateProfile(auth.currentUser, {
            displayName: newUserName,
          });
          user.displayName = newUserName;

          localStorage.setItem("user", user);
          // console.log("User is created", user);
          const { displayName, uid, email, phoneNumber } = user;
          dispatch(
            setUser({
              displayName,
              id: uid,
              email,
              phoneNumber,
              uid: uid,
              isSeller: false,
            })
          );
          dispatch(setOpenLogin(false));
          toast.success("Login successful!");
        })
        .catch((error) => {
          toast.error("Please enter correct details" + error.message);
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorMessage);
        });
    } else {
      console.log(`user is ${newUserEmail} and password ${newUserPassword}`);
      console.log(" err in input");
    }
  };

  const handleWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        const { displayName, uid, email, phoneNumber } = user;
        dispatch(
          setUser({
            displayName,
            id: uid,
            email,
            phoneNumber,
            uid: uid,
            isSeller: false,
          })
        );
        dispatch(setOpenLogin(false));
        toast.success("Login successful!");
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        toast.error("Please enter correct details" + error.message);
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log("Error in signin");
      });
    // console.log("signin....");
  };

  const forgotPassword = () => {
    if (!Email.match(validRegex)) {
      setEmailError("Invalid email");
    } else {
      setEmailError("");
      sendPasswordResetEmail(auth, Email)
        .then(() => {
          toast.success(
            "Password reset email has been sent to your email at " + Email
          );
        })
        .catch((error) => {
          toast.error("Please enter correct details" + error.message);
          const errorCode = error.code;
          const errorMessage = error.message;
        });
    }
  };

  function handleChangeOTP(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
    setOtpLength(otp.join("").length);
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
    setOtpLength(otp.join("").length);
  }

  const sendOTP = async () => {
    let err = false;
    var val = Math.floor(1000 + Math.random() * 9000);
    setCorrectOTP(val);

    if (newUserPassword.length < 6) {
      setNewUserPasswordError("password must be atleat of 6 characters");
      err = true;
    } else {
      setNewUserPasswordError("");
    }
    if (!newUserEmail.match(validRegex)) {
      setNewUserEmailError("Invalid email");
      err = true;
    } else {
      setNewUserEmailError("");
    }
    if (newUserName.length < 3) {
      setNewUserNameError("Name must be more than 3 characters");
      err = true;
    } else {
      setNewUserNameError("");
    }
    if (!(newUserPassword === passwordMatch)) {
      setPasswordMatchError("Password must match");
      err = true;
    } else {
      setPasswordMatchError("");
    }
    if (!err) {
      // send OTP request here
      const message =
        "<html><head></head><body><p>Hello,</p>Your OTP for verification is " +
        val.toString() +
        "<br/><br/>Thanks,<br/>Your Indilist Team</p></body></html>";

      const data = {
        sender: {
          name: "Indilist",
          email: "support@indilist.com",
        },
        to: [
          {
            email: newUserEmail,
            name: newUserName,
          },
        ],
        subject: "OTP for Verification",
        htmlContent: message,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
        },
      };

      // Add Your Key Here!!!
      axios.defaults.headers.common = {
        "api-key": BrevoAPIKeys[Math.round(0 + Math.random() * 2)],
      };

      axios({
        method: "post",
        url: "https://api.brevo.com/v3/smtp/email",
        data: data,
        config,
      })
        .then((res) => {
          toast.success("OTP has been sent on " + newUserEmail);
        })
        .catch((err) => {
          toast.error("Unable to send OTP " + err.message);
        });
      // if otp match fails setOtpError(true)

      setOtpSent(true); // on successs of otp sent do these
      setMinutes(1);
      setSeconds(59);
      setTimeout(() => {
        // delaying so that ref is set first
        otpBoxReference.current[0].focus();
      }, 100);
    }
  };

  const handleResendOTP = async () => {
    var val = Math.floor(1000 + Math.random() * 9000);
    setCorrectOTP(val);
    // on resend reset time
    const message =
      "<html><head></head><body><p>Hello,</p>Your OTP for verification is " +
      val.toString() +
      "<br/><br/>Thanks,<br/>Your Indilist Team</p></body></html>";

    const data = {
      sender: {
        name: "Indilist",
        email: "support@indilist.com",
      },
      to: [
        {
          email: newUserEmail,
          name: newUserName,
        },
      ],
      subject: "OTP for Verification",
      htmlContent: message,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    };

    // Add Your Key Here!!!
    axios.defaults.headers.common = {
      "api-key": BrevoAPIKeys[Math.round(0 + Math.random() * 2)],
    };

    axios({
      method: "post",
      url: "https://api.brevo.com/v3/smtp/email",
      data: data,
      config,
    })
      .then((res) => {
        toast.success("OTP has been sent on " + newUserEmail);
      })
      .catch((err) => {
        toast.error("Unable to send OTP " + err.message);
      });
    setMinutes(1);
    setSeconds(59);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <ModalProvider
      appElement={document.getElementById("root")}
      className="m-auto sm:!w-[534px]"
      overlayClassName=" bg-black_900_7f z-20 fixed h-[100vh] overflow-y-hidden  flex  inset-y-[0] w-full"
      isOpen={isOpen}
      onAfterOpen={() => {
        let doc = document.getElementById("root");
        doc.style.height = "100vh";
        doc.style.overflowY = "hidden";
      }}
      onAfterClose={() => {
        document.getElementById("root").style.overflowY = "scroll";
      }}
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        // props.setOpen(false);
        dispatch(setOpenLogin(false));
      }}
    >
      <div className="w-full max-h-[90vh]  rounded-[12px] bg-white_A700 text-gray_700 overflow-y-auto p-8 ">
        <div className="flex justify-between ">
          <Text className="text-2xl font-medium font-inter">COME ON IN</Text>
          <Text
            className="text-xl font-normal cursor-pointer font-inter"
            onClick={() => dispatch(setOpenLogin(false))}
          >
            X
          </Text>
        </div>
        <div className="flex flex-row space-x-10 text-xl font-normal border-b-2 mt-9 font-inter border-gray_700_cc">
          <Text
            className={` cursor-pointer border-gray_700 ${
              isNew ? " text-gray_700_cc" : " border-b-2"
            }`}
            onClick={() => setIsNew(false)}
          >
            SIGN IN
          </Text>
          <Text
            className={`cursor-pointer border-gray_700  ${
              isNew ? " border-b-2" : "text-gray_700_cc"
            }`}
            onClick={() => setIsNew(true)}
          >
            I'M NEW HERE
          </Text>
        </div>
        {isNew ? (
          <div className="grid py-3 gap-y-3 ">
            <div>
              <Text>Name*</Text>
              <Input
                fill="White_A700"
                outline="gray_700_01"
                className="w-full h-11"
                value={newUserName}
                onChange={setNewUserName}
                errors={newUserNameError}
              />
            </div>
            <div>
              <Text>Email address*</Text>
              <Input
                fill="White_A700"
                outline="gray_700_01"
                className="w-full h-11"
                value={newUserEmail}
                onChange={setNewUserEmail}
                errors={newUserEmailError}
              />
            </div>

            <div>
              <Text>Password*</Text>
              <Input
                fill="White_A700"
                outline="gray_700_01"
                className="w-full h-11"
                type="password"
                value={newUserPassword}
                onChange={setNewUserPassword}
                errors={newUserPasswordError}
              />
            </div>
            <div>
              <Text>Confirm password*</Text>
              <Input
                fill="White_A700"
                outline="gray_700_01"
                className="w-full h-11"
                type="password"
                value={passwordMatch}
                onChange={setPasswordMatch}
                errors={passwordMatchError}
              />
            </div>
            <div>
              <div>
                <Button
                  variant="fill"
                  color="gray_700"
                  size="sm"
                  className={`px-8 text-xl font-medium cursor-pointer font-inter  w-full ${
                    otpSent ? "hidden" : "block"
                  }`}
                  onClick={() => sendOTP()}
                >
                  Sign Up
                </Button>
              </div>
              <div className={`${otpSent ? "block" : "hidden"}`}>
                <Text
                  className=" text-[13.15px] md:text-lg text-gray_700_02"
                  size="txtInterRegular18"
                >
                  <span className="font-normal text-left text-gray_700_02 font-inter">
                    We have sent you a{" "}
                  </span>
                  <span className="font-semibold text-left text-blue_gray_900 font-inter">
                    One Time Password(OTP)
                  </span>
                  <span className="font-normal text-left text-gray_700_02 font-inter">
                    .
                  </span>
                </Text>
              </div>
              <div
                className={` ${
                  otpSent ? "flex" : "hidden"
                } flex-wrap items-center gap-4`}
              >
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    value={digit}
                    maxLength={1}
                    onChange={(e) => handleChangeOTP(e.target.value, index)}
                    onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                    ref={(reference) =>
                      (otpBoxReference.current[index] = reference)
                    }
                    className={`border h-10 md:h-12 aspect-square text-gray_700 ${
                      otpError ? "border-red_800" : "border-gray_700"
                    }  p-3 md:px-4 block focus:border-2 focus:outline-none appearance-none`}
                  />
                ))}
                <Button
                  className={`cursor-pointer font-medium px-4 py-2.5 md:py-3.5 sm:px-10 leading-[normal] ${
                    otpLength < numberOfDigits
                      ? "pointer-events-none bg-white_A100 text-gray_700_01"
                      : "pointer-events-auto"
                  }  text-base text-center`}
                  shape="square"
                  color="gray_700_01"
                  size="md"
                  variant="fill"
                  onClick={() => handleNewUserLogin()}
                >
                  Verify
                </Button>
              </div>
              <Text
                className={`text-base text-center text-gray_700_01 ${
                  minutes === 0 && seconds === 0 ? "hidden" : "block"
                }`}
                size="txtInterMedium16"
              >
                Resend in 0{minutes}:{seconds < 10 && "0"}
                {seconds}
              </Text>
              <Text
                className={`text-sm text-center text-black_900 ${
                  otpSent ? "block" : "hidden"
                }`}
                size="txtInterRegular14"
              >
                <span className="font-normal text-gray_700_01 font-inter">
                  <>I didn&#39;t receive any code.</>
                </span>
                <span className="font-normal text-green_A700_00 font-inter">
                  {" "}
                </span>
                <span
                  className={`font-medium cursor-pointer ${
                    seconds === 0 && minutes === 0
                      ? "pointer-events-auto"
                      : "pointer-events-none"
                  } text-gray_700_01 font-inter`}
                  onClick={() => handleResendOTP()}
                >
                  RESEND
                </span>
              </Text>
            </div>
            {/* <Text>
              Lorem ipsum dolor sit amet consectetur. Nibh tellus mattis nisi
              porta lobortis urna eget. Odio ipsum consectetur blandit libero
              sem.
            </Text> */}
            {/* <Button
              variant="fill"
              color="gray_700_01"
              size="sm"
              className="text-xl font-medium cursor-pointer font-inter"
              onClick={handleNewUserLogin}
            >
              Sign Up
            </Button> */}
            <Text className="text-lg font-normal text-center font-inter ">
              OR
            </Text>
            <div>
              <Button
                className="flex justify-center w-full gap-2 align-middle cursor-pointer "
                variant="outline"
                color="gray_700_01"
                size="sm"
                onClick={handleWithGoogle}
              >
                <Img src="images/Icon-Google.svg" className="w-6 h-6" />{" "}
                Continue with Google
              </Button>
            </div>
          </div>
        ) : (
          <div className="grid py-3 gap-y-3 ">
            <div>
              <Text>Email</Text>
              <Input
                fill="White_A700"
                outline="gray_700_01"
                className="w-full px-3 h-11"
                value={Email}
                onChange={setEmail}
                errors={emailError}
              />
            </div>

            <div>
              <Text>Password</Text>
              <Input
                fill="White_A700"
                outline="gray_700_01"
                className="w-full px-3 h-11"
                type="password"
                value={password}
                onChange={setPassword}
                errors={passwordError}
              />
            </div>
            <div>
              <CheckBox
                label="Keep me signed in"
                id="signed"
                inputClassName=" h-6 w-6 "
                className="space-x-2"
                value={keepSigned}
                onChange={setKeepSigned}
              />
            </div>
            <div className="underline" onClick={forgotPassword}>
              Forgot your password?
            </div>
            <Button
              variant="fill"
              color="gray_700_01"
              size="sm"
              className="text-xl font-medium cursor-pointer font-inter"
              onClick={handleLogin}
            >
              Log in
            </Button>
            <Text className="text-lg font-normal text-center font-inter ">
              OR
            </Text>
            <div>
              <Button
                className="flex justify-center w-full gap-2 align-middle cursor-pointer "
                variant="outline"
                color="gray_700_01"
                size="sm"
                onClick={handleWithGoogle}
              >
                <Img src="images/Icon-Google.svg" className="w-6 h-6" />{" "}
                Continue with Google
              </Button>
            </div>
          </div>
        )}
      </div>
    </ModalProvider>
  );
};

export default SignupOneModal;
