import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const favouriteSlice = createSlice({
  name: "wishList",
  initialState: {
    favouriteItems: [],
    favouriteItemIds: [],
    totalQuantity: 0,
  },
  reducers: {
    setWishList(state, action) {
      state.favouriteItems = action.payload.data;
      state.favouriteItemIds = action.payload.Ids;
      state.totalQuantity = action.payload.Ids.length;
    },
    addToFavourite(state, action) {
      const favouriteItemIndex = state.favouriteItems.findIndex(
        (item) => item.id === action.payload.id
      );
      if (!(favouriteItemIndex >= 0)) {
        let item = { ...action.payload };
        item.isFavourite = true;
        state.favouriteItemIds.push(item.id);
        state.favouriteItems.push(item);
        state.totalQuantity += 1;
      }
    },
    removeFromfavourite(state, action) {
      let favIds = [];
      const infavouriteItems = state.favouriteItems.filter((favouriteItem) => {
        if (favouriteItem.id !== action.payload.id) {
          favIds.push(favouriteItem.id);
          return favouriteItem;
        }
      });

      state.favouriteItemIds = favIds;
      state.favouriteItems = infavouriteItems;
      state.totalQuantity -= 1;
    },
    deleteAllFromfavourite(state, action) {
      const items = action.payload;
      let tempItems = [];
      let tempIds = [];
      items.forEach((item) => {
        if (!item.isAvailable && item.isVisible) {
          tempIds.push(item.id);
          tempItems.push(item);
          toast.info(`${item.brandName} ${item.name} is out of stock!`);
        }
      });
      state.favouriteItems = tempItems;
      state.favouriteItemIds = tempIds;
      state.totalQuantity = tempIds.length;
    },
  },
});

export default favouriteSlice.reducer;
export const {
  addToFavourite,
  removeFromfavourite,
  deleteAllFromfavourite,
  setWishList,
} = favouriteSlice.actions;
