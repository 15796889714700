import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useGoogleLogin } from "@react-oauth/google";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsSeller,
  setOpenLogin,
  setSellerProfile,
  setUser,
} from "../../../../store/slices/userSlice";

import { Button, Img, Text } from "../../../../SellersSrc/src/components";
import { CheckBox, Input } from "../../../../components";
import Header1 from "../../../../components/Header1";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from "firebase/firestore";

const SignInPage = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const dispatch = useDispatch();
  const provider = new GoogleAuthProvider();
  const user = useSelector((state) => {
    return state?.user?.user;
  });
  // const googleSignIn = useGoogleLogin({
  //   onSuccess: (res) => {
  //     console.log("res", res);
  //     alert("Login successfull. 😍");
  //   },
  // });
  let validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const [Email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [keepSigned, setKeepSigned] = useState(false);
  const handleLogin = () => {
    if (!Email.match(validRegex)) {
      setEmailError("Invalid email");
    } else {
      setEmailError("");
      signInWithEmailAndPassword(auth, Email, password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          console.log("User is logged in", user);
          const { displayName, uid, email, phoneNumber } = user;
          dispatch(
            setUser({
              displayName,
              id: uid,
              email,
              phoneNumber,
              uid: uid,
            })
          );
          await getDoc(doc(collection(getFirestore(), "Sellers"), uid)).then(
            (doc) => {
              if (doc.exists) {
                dispatch(setSellerProfile({ ...doc.data(), id: doc.id }));
              }
            }
          );
          await setDoc(
            doc(collection(getFirestore(), "Sellers"), uid),
            {
              email: email,
            },
            { merge: true }
          );
          dispatch(setIsSeller(true));
          toast.success("Login successful !");
          navigate("/seller-home");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          toast.error(errorMessage);
        });
    }
  };
  const handleWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        const { displayName, uid, email, phoneNumber } = user;
        dispatch(
          setUser({
            displayName,
            id: uid,
            email,
            phoneNumber,
            uid: uid,
          })
        );
        await getDoc(doc(collection(getFirestore(), "Sellers"), uid)).then(
          (doc) => {
            if (doc.exists) {
              dispatch(setSellerProfile({ ...doc.data(), id: doc.id }));
            }
          }
        );
        await setDoc(
          doc(collection(getFirestore(), "Sellers"), uid),
          {
            email: email,
          },
          { merge: true }
        );
        dispatch(setIsSeller(true));
        // IdP data available using getAdditionalUserInfo(result)
        // ...
        toast.success("Login successful !");
        navigate("/seller-home");
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log("Error in signin");
        toast.error(errorMessage);
      });
  };

  const forgotPassword = () => {
    if (!Email.match(validRegex)) {
      setEmailError("Invalid email");
    } else {
      setEmailError("");
      sendPasswordResetEmail(auth, Email)
        .then(() => {
          toast.success(
            "Password reset email has been sent to your email at " + Email
          );
        })
        .catch((error) => {
          toast.error("Please enter correct details" + error.message);
          const errorCode = error.code;
          const errorMessage = error.message;
        });
    }
  };

  useEffect(() => {
    if (user) {
      navigate("/seller-home");
    }
  }, []);
  return (
    <>
      <Header1 />
      <div className="bg-white_A700 flex flex-col font-inter items-center justify-start mx-auto pb-[94px] w-full">
        <div className="flex justify-center w-full py-2 border-t-2 border-b-2 sm:py-6 border-gray_700_cc">
          <Text className="text-lg font-medium sm:text-2xl">COME ON IN</Text>
        </div>

        <div className="flex flex-col  justify-start mt-7 px-5 sm:w-[586px] w-full">
          <Text
            className="text-[30.04px] sm:text-[32.04px] md:text-[34.04px] text-left w-full text-gray_700_01"
            size="txtInterMedium3404"
          >
            SIGN IN
          </Text>
          <div className="grid w-full py-3 gap-y-3">
            <div>
              <Text>Email</Text>
              <Input
                fill="White_A700"
                outline="gray_700_01"
                className="w-full px-3 h-11"
                value={Email}
                onChange={setEmail}
                errors={emailError}
              />
            </div>

            <div>
              <Text>Password</Text>
              <Input
                fill="White_A700"
                outline="gray_700_01"
                className="w-full px-3 h-11"
                type="password"
                value={password}
                onChange={setPassword}
                errors={passwordError}
              />
            </div>

            <CheckBox
              label="Keep me signed in"
              inputClassName=" h-6 w-6 "
              className="flex justify-start w-full space-x-2"
              id="signed"
              value={keepSigned}
              onChange={setKeepSigned}
            />

            <div
              className="text-left underline w-fit "
              onClick={forgotPassword}
            >
              Forgot your password?
            </div>
            <Button
              variant="fill"
              color="gray_700_01"
              size="sm"
              className="text-xl font-medium cursor-pointer font-inter"
              onClick={handleLogin}
            >
              Log in
            </Button>
            <Text className="text-lg font-normal text-center font-inter ">
              OR
            </Text>
            <div>
              <Button
                className="flex justify-center w-full gap-2 align-middle cursor-pointer "
                variant="outline"
                color="gray_700_01"
                size="sm"
                onClick={handleWithGoogle}
              >
                <Img src="images/Icon-Google.svg" className="w-6 h-6" />
                Continue with Google
              </Button>
            </div>
            <div className="flex flex-row items-start justify-center w-full font-poppins">
              <Text
                className="text-[19px] sm:text-[21px] md:text-[23px] text-gray_700_01"
                size="txtPoppinsRegular23"
              >
                <>Don&#39;t have an account.</>
              </Text>
              <a className="ml-[5px] md:text-2xl sm:text-[22px] text-gray_700_01 text-xl underline">
                <Text
                  className="cursor-pointer "
                  size="txtPoppinsMedium24"
                  onClick={() => navigate("/seller-signup")}
                >
                  Sign Up
                </Text>
              </a>
            </div>
          </div>
          {/* <div className="flex flex-col items-start justify-start w-full">
            <div className="flex flex-col items-center justify-start w-full">
              <div className="flex flex-col gap-[7.86px] h-[262px] md:h-auto items-start justify-start w-[585px] sm:w-full">
                <Text
                  className="sm:text-[23.57px] md:text-[25.57px] text-[27.57px] text-gray_700_01 w-auto"
                  size="txtInterRegular2757"
                >
                  Email
                </Text>
                <div className="border border-gray_700_01 border-solid h-[76px] w-full"></div>
                <div className="flex flex-col gap-[7px] items-start justify-end pt-1.5 w-full">
                  <Text
                    className="sm:text-[23.57px] md:text-[25.57px] text-[27.57px] text-gray_700_01"
                    size="txtInterRegular2757"
                  >
                    Password
                  </Text>
                  <div className="border border-gray_700_01 border-solid flex flex-col items-end justify-start p-[22px] sm:px-5 w-full">
                    <Img
                      className="h-[31px] mr-[9px] w-[31px]"
                      src="images/img_eye.svg"
                      alt="eye"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start mt-5 w-[222px]">
              <div className="flex flex-col items-center justify-start w-full gap-4">
                <div className="flex flex-row gap-[9px] items-start justify-start w-[97%] md:w-full">
                  <div className="border border-gray_700_01 border-solid h-[27px] mb-1.5 w-[27px]"></div>
                  <Text
                    className="mt-[9px] sm:text-[16.51px] md:text-[18.51px] text-[20.51px] text-gray_700_01 w-auto"
                    size="txtInterRegular2051"
                  >
                    Keep me signed in
                  </Text>
                </div>
                <a
                  href="javascript:"
                  className="sm:text-[16.51px] md:text-[18.51px] text-[20.51px] text-gray_700_01 underline w-auto"
                >
                  <Text size="txtInterRegular2051">Forgot your password?</Text>
                </a>
              </div>
            </div>
            <div className="flex flex-col gap-[25px] items-center justify-start mt-[30px] w-full">
              <Button
                className="cursor-pointer font-medium min-w-[582px] sm:min-w-full sm:text-[27.93px] md:text-[29.93px] text-[31.93px] text-center"
                shape="square"
                color="gray_700_01"
                size="lg"
                variant="fill"
              >
                Log In
              </Button>
              <Text
                className="sm:text-[20.41px] md:text-[22.41px] text-[24.41px] text-gray_700_01"
                size="txtInterRegular2441"
              >
                OR
              </Text>
              <Button
                className="common-pointer border border-gray_700_01 border-solid cursor-pointer flex items-center justify-center min-w-[585px] sm:min-w-full"
                // onClick={() => googleSignIn()}
                leftIcon={
                  <Img
                    className="h-[38px] mr-[15px]"
                    src="images/img_icongoogle.svg"
                    alt="Icon-Google"
                  />
                }
                shape="square"
                color="white_A700"
                size="lg"
                variant="fill"
              >
                <div className="font-medium md:text-[22.17px] sm:text-[20.17px] text-[24.17px] text-left">
                  Continue with Google
                </div>
              </Button>
            </div>
            <div className="flex flex-row font-poppins items-start justify-center ml-28 md:ml-[0] mt-11 w-[62%] md:w-full">
              <Text
                className="sm:text-[19px] md:text-[21px] text-[23px] text-gray_700_01"
                size="txtPoppinsRegular23"
              >
                <>Don&#39;t have an account.</>
              </Text>
              <a className="ml-[5px] text-2xl md:text-[22px] text-gray_700_01 sm:text-xl underline">
                <Text
                  className="common-pointer"
                  size="txtPoppinsMedium24"
                  onClick={() => navigate("/signup")}
                >
                  Sign Up
                </Text>
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default SignInPage;
