import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useGoogleLogin } from "@react-oauth/google";

import { useDispatch, useSelector } from "react-redux";
import {
  setIsSeller,
  setOpenLogin,
  setSellerProfile,
  setUser,
} from "../../../../store/slices/userSlice";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import {
  collection,
  getDocs,
  addDoc,
  getFirestore,
  query,
  where,
  setDoc,
  doc,
  getDoc,
} from "firebase/firestore";

import { Button, Img, Input, List, Text } from "../../components";
import Header1 from "../../../../components/Header1";
import { toast } from "react-toastify";
import axios from "axios";

const SignUpPage = (props) => {
  const BrevoAPIKeys = [
    "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",
    "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",
    "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",
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const provider = new GoogleAuthProvider();

  const user = useSelector((state) => {
    return state?.user?.user;
  });

  useEffect(() => {
    if (user && user.sellerProfile?.isApproved) {
      navigate("/seller-home");
    } else if (user) {
      navigate("/settings-edit");
    }
  }, []);

  // const googleSignIn = useGoogleLogin({
  //   onSuccess: (res) => {
  //     console.log("res", res);
  //     alert("Login successfull. 😍");
  //   },
  // });
  const auth = getAuth();

  let validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const numberOfDigits = 4;

  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState("");
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [otpLength, setOtpLength] = useState(0);
  const otpBoxReference = useRef([]);
  const [otpSent, setOtpSent] = useState(false);
  const [correctOTP, setCorrectOTP] = useState(0);

  const [newUserNameError, setNewUserNameError] = useState("");
  const [newUserEmailError, setNewUserEmailError] = useState("");
  const [newUserPasswordError, setNewUserPasswordError] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [otpError, setOtpError] = useState(false);

  const handleNewUserLogin = () => {
    let err = false;

    if (newUserPassword.length < 6) {
      setNewUserPasswordError("password must be atleat of 6 characters");
      err = true;
    } else {
      setNewUserPasswordError("");
    }
    if (!newUserEmail.match(validRegex)) {
      setNewUserEmailError("Invalid email");
      err = true;
    } else {
      setNewUserEmailError("");
    }
    if (newUserName.length < 3) {
      setNewUserNameError("Name must be more than 3 characters");
      err = true;
    } else {
      setNewUserNameError("");
    }
    if (!(newUserPassword === passwordMatch)) {
      setPasswordMatchError("Password must match");
      err = true;
    } else {
      setPasswordMatchError("");
    }

    // match OTP here
    console.log(otp);
    if (otp.join("") !== correctOTP.toString()) {
      toast.error("Please enter correct OTP");
      err = true;
    }

    // if matches proceed else return

    if (!err) {
      console.log(`user is ${newUserEmail} and password ${newUserPassword}`);
      createUserWithEmailAndPassword(auth, newUserEmail, newUserPassword)
        .then(async (userCredential) => {
          let user = { ...userCredential.user };
          await auth.authStateReady();
          if (!auth.currentUser) {
            return toast.error("something went wrong !");
          }
          await updateProfile(auth.currentUser, {
            displayName: newUserName,
          });
          user.displayName = newUserName;

          const { displayName, uid, email, phoneNumber } = user;
          setDoc(
            doc(collection(getFirestore(), "Sellers"), auth.currentUser.uid),
            {
              name: newUserName,
              email: email,
              shopName: newUserName,
              followers: 0,
              products: 0,
              rating: 5,
              reviews: 0,
              sellerId: user.uid,
              instagram: "",
              facebook: "",
              image: "",
              isApproved: false,
              logo: "",
              cover: "",
              description: "Write your brand story here",
              isStoreOpen: true,
              isDelivering: true,
              isAcceptingOrder: true,
              isNotifyOrder: true,
              isCODAvailable: true,
            },
            { merge: true }
          )
            .then(() => {
              console.log("Seller added");
              console.log("User is created", user);
              dispatch(
                setUser({ displayName, id: uid, email, phoneNumber, uid: uid })
              );
              dispatch(setIsSeller(true));
              navigate("/settings-edit");
            })
            .catch((error) => {
              console.log("Error in adding seller," + error.message);
            });

          console.log("User is created", user);

          dispatch(
            setUser({
              displayName,
              id: uid,
              email,
              phoneNumber,
              uid: uid,
            })
          );
          dispatch(setIsSeller(true));
          navigate("/settings-edit");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorMessage);
          toast.error(errorMessage);
        });
    } else {
      console.log(`user is ${newUserEmail} and password ${newUserPassword}`);
      console.log(" err in input");
      toast.error("Error in Input fields");
    }
  };
  const handleWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        let user = result.user;
        const { displayName, uid, email, phoneNumber } = user;
        dispatch(
          setUser({
            displayName,
            id: uid,
            email,
            phoneNumber,
            uid: uid,
          })
        );

        // IdP data available using getAdditionalUserInfo(result)
        // ...
        const sellerInfo = await getDoc(
          doc(collection(getFirestore(), "Sellers"), uid)
        );
        if (sellerInfo.exists() && sellerInfo.data()?.isApproved) {
          dispatch(
            setSellerProfile({ ...sellerInfo.data(), id: sellerInfo.id })
          );
          return navigate("/seller-home");
        } else {
          await setDoc(
            doc(collection(getFirestore(), "Sellers"), uid),
            {
              name: newUserName,
              email: email,
              shopName: newUserName,
              followers: 0,
              products: 0,
              rating: 5,
              numRatings: 0,
              reviews: 0,
              sellerId: user.uid,
              instagram: "",
              facebook: "",
              twitter: "",
              image: "",
              isApproved: false,
              logo: "",
              cover: "",
              description: "Write your brand story here",
              categories: [],
              isStoreOpen: true,
              isDelivering: true,
              isAcceptingOrder: true,
              isNotifyOrder: true,
              isCODAvailable: true,
            },
            { merge: true }
          )
            .then(() => {
              dispatch(setIsSeller(true));
              navigate("/settings-edit");
            })
            .catch((error) => {
              console.log("Error in adding seller," + error.message);
            });
        }
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log("Error in signin");
        toast.error(errorMessage);
      });
  };

  function handleChangeOTP(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
    setOtpLength(otp.join("").length);
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
    setOtpLength(otp.join("").length);
  }

  const sendOTP = async () => {
    let err = false;
    var val = Math.floor(1000 + Math.random() * 9000);
    setCorrectOTP(val);
    if (newUserPassword.length < 6) {
      setNewUserPasswordError("password must be atleat of 6 characters");
      err = true;
    } else {
      setNewUserPasswordError("");
    }
    if (!newUserEmail.match(validRegex)) {
      setNewUserEmailError("Invalid email");
      err = true;
    } else {
      setNewUserEmailError("");
    }
    if (newUserName.length < 3) {
      setNewUserNameError("Name must be more than 3 characters");
      err = true;
    } else {
      setNewUserNameError("");
    }
    if (!(newUserPassword === passwordMatch)) {
      setPasswordMatchError("Password must match");
      err = true;
    } else {
      setPasswordMatchError("");
    }
    if (!err) {
      // send OTP request here
      const message =
        "<html><head></head><body><p>Hello,</p>Your OTP for verification is " +
        val.toString() +
        "<br/><br/>Thanks,<br/>Your Indilist Team</p></body></html>";

      const data = {
        sender: {
          name: "Indilist",
          email: "support@indilist.com",
        },
        to: [
          {
            email: newUserEmail,
            name: newUserName,
          },
        ],
        subject: "OTP for Verification",
        htmlContent: message,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
        },
      };

      // Add Your Key Here!!!
      axios.defaults.headers.common = {
        "api-key": BrevoAPIKeys[Math.round(0 + Math.random() * 2)],
      };

      axios({
        method: "post",
        url: "https://api.brevo.com/v3/smtp/email",
        data: data,
        config,
      })
        .then((res) => {
          toast.success("OTP has been sent on " + newUserEmail);
        })
        .catch((err) => {
          toast.error("Unable to send OTP " + err.message);
        });
      // if otp match fails setOtpError(true)

      setOtpSent(true); // on successs of otp sent do these
      setMinutes(1);
      setSeconds(59);
      setTimeout(() => {
        // delaying so that ref is set first
        otpBoxReference.current[0].focus();
      }, 100);
    }
  };

  const handleResendOTP = async () => {
    var val = Math.floor(1000 + Math.random() * 9000);
    setCorrectOTP(val);
    // on resend reset time
    const message =
      "<html><head></head><body><p>Hello,</p>Your OTP for verification is " +
      val.toString() +
      "<br/><br/>Thanks,<br/>Your Indilist Team</p></body></html>";

    const data = {
      sender: {
        name: "Indilist",
        email: "support@indilist.com",
      },
      to: [
        {
          email: newUserEmail,
          name: newUserName,
        },
      ],
      subject: "OTP for Verification",
      htmlContent: message,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    };

    // Add Your Key Here!!!
    axios.defaults.headers.common = {
      "api-key": BrevoAPIKeys[Math.round(0 + Math.random() * 2)],
    };

    axios({
      method: "post",
      url: "https://api.brevo.com/v3/smtp/email",
      data: data,
      config,
    })
      .then((res) => {
        toast.success("OTP has been sent on " + newUserEmail);
      })
      .catch((err) => {
        toast.error("Unable to send OTP " + err.message);
      });
    // on resend reset time
    setMinutes(1);
    setSeconds(59);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <>
      <Header1 />
      <div className="bg-white_A700 flex flex-col font-inter items-center justify-start mx-auto pb-[107px] w-full">
        <div className="flex justify-center w-full py-2 border-t-2 border-b-2 sm:py-6 border-gray_700_cc">
          <Text className="text-lg font-medium sm:text-2xl">COME ON IN</Text>
        </div>
        <div className="flex flex-col  justify-start mt-7 px-5 sm:w-[586px] w-full">
          <Text
            className="text-[30.04px] sm:text-[32.04px] md:text-[34.04px] text-left w-full text-gray_700_01"
            size="txtInterMedium3404"
          >
            SIGN UP
          </Text>

          <div className="grid py-3 gap-y-3 ">
            <div>
              <Text>Name*</Text>
              <Input
                fill="White_A700"
                outline="gray_700_01"
                className="w-full px-2 h-11"
                value={newUserName}
                onChange={setNewUserName}
                errors={newUserNameError}
              />
            </div>
            <div>
              <Text>Email address*</Text>
              <Input
                fill="White_A700"
                outline="gray_700_01"
                className="w-full px-2 h-11"
                value={newUserEmail}
                onChange={setNewUserEmail}
                errors={newUserEmailError}
              />
            </div>

            <div>
              <Text>Password*</Text>
              <Input
                fill="White_A700"
                outline="gray_700_01"
                className="w-full px-2 h-11"
                type="password"
                value={newUserPassword}
                onChange={setNewUserPassword}
                errors={newUserPasswordError}
              />
            </div>
            <div>
              <Text>Confirm password*</Text>
              <Input
                fill="White_A700"
                outline="gray_700_01"
                className="w-full px-2 h-11"
                type="password"
                value={passwordMatch}
                onChange={setPasswordMatch}
                errors={passwordMatchError}
              />
            </div>
            <div>
              <div>
                <Button
                  variant="fill"
                  color="gray_700"
                  size="sm"
                  className={`px-8 text-xl font-medium cursor-pointer font-inter  w-full ${
                    otpSent ? "hidden" : "block"
                  }`}
                  onClick={() => sendOTP()}
                >
                  Sign Up
                </Button>
              </div>
              <div className={`${otpSent ? "block" : "hidden"}`}>
                <Text
                  className=" text-[13.15px] md:text-lg text-gray_700_02"
                  size="txtInterRegular18"
                >
                  <span className="font-normal text-left text-gray_700_02 font-inter">
                    We have sent you a{" "}
                  </span>
                  <span className="font-semibold text-left text-blue_gray_900 font-inter">
                    One Time Password(OTP)
                  </span>
                  <span className="font-normal text-left text-gray_700_02 font-inter">
                    .
                  </span>
                </Text>
              </div>
              <div
                className={` ${
                  otpSent ? "flex" : "hidden"
                } flex-wrap items-center gap-4`}
              >
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    value={digit}
                    maxLength={1}
                    onChange={(e) => handleChangeOTP(e.target.value, index)}
                    onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                    ref={(reference) =>
                      (otpBoxReference.current[index] = reference)
                    }
                    className={`border h-10 md:h-12 aspect-square text-gray_700 ${
                      otpError ? "border-red_800" : "border-gray_700"
                    }  p-3 md:px-4 block focus:border-2 focus:outline-none appearance-none`}
                  />
                ))}
                <Button
                  className={`cursor-pointer font-medium px-4 py-2.5 md:py-3.5 sm:px-10 leading-[normal] ${
                    otpLength < numberOfDigits
                      ? "pointer-events-none bg-white_A100 text-gray_700_01"
                      : "pointer-events-auto"
                  }  text-base text-center`}
                  shape="square"
                  color="gray_700_01"
                  size="md"
                  variant="fill"
                  onClick={() => handleNewUserLogin()}
                >
                  Verify
                </Button>
              </div>
              <Text
                className={`text-base text-center text-gray_700_01 ${
                  minutes === 0 && seconds === 0 ? "hidden" : "block"
                }`}
                size="txtInterMedium16"
              >
                0{minutes}:{seconds < 10 && "0"}
                {seconds}
              </Text>
              <Text
                className={`text-sm text-center text-black_900 ${
                  otpSent ? "block" : "hidden"
                }`}
                size="txtInterRegular14"
              >
                <span className="font-normal text-gray_700_01 font-inter">
                  <>I didn&#39;t receive any code.</>
                </span>
                <span className="font-normal text-green_A700_00 font-inter">
                  {" "}
                </span>
                <span
                  className={`font-medium cursor-pointer ${
                    seconds === 0 && minutes === 0
                      ? "pointer-events-auto"
                      : "pointer-events-none"
                  } text-gray_700_01 font-inter`}
                  onClick={() => handleResendOTP()}
                >
                  RESEND
                </span>
              </Text>
            </div>
            {/* <Text>
              Lorem ipsum dolor sit amet consectetur. Nibh tellus mattis nisi
              porta lobortis urna eget. Odio ipsum consectetur blandit libero
              sem.
            </Text> */}
            {/* <Button
              variant="fill"
              color="gray_700_01"
              size="sm"
              className="text-xl font-medium cursor-pointer font-inter"
              onClick={handleNewUserLogin}
            >
              Log in
            </Button> */}
            <Text className="text-lg font-normal text-center font-inter ">
              OR
            </Text>
            <div>
              <Button
                className="flex justify-center w-full gap-2 align-middle cursor-pointer "
                variant="outline"
                color="gray_700_01"
                size="sm"
                onClick={handleWithGoogle}
              >
                <Img src="images/Icon-Google.svg" className="w-6 h-6" />{" "}
                Continue with Google
              </Button>
            </div>
            <div className="flex flex-row items-start justify-center w-full font-poppins">
              <Text
                className="text-[19px] sm:text-[21px] md:text-[23px] text-gray_700_01"
                size="txtPoppinsRegular23"
              >
                Already have a account.
              </Text>
              <a className="ml-[5px] md:text-2xl sm:text-[22px] text-gray_700_01 text-xl underline">
                <Text
                  className="cursor-pointer "
                  size="txtPoppinsMedium24"
                  onClick={() => navigate("/seller-signin")}
                >
                  Sign In
                </Text>
              </a>
            </div>
          </div>
        </div>
        {/* <Text
          className="md:ml-[0] ml-[427px] mt-[65px] sm:text-[30.04px] md:text-[32.04px] text-[34.04px] text-gray-700_01"
          size="txtInterMedium3404"
        >
          SIGN UP
        </Text>
        <div className="flex flex-col items-center mt-[37px] md:px-10 sm:px-5 px-[426px] w-full">
          <div className="flex flex-col gap-[46px] items-center justify-start w-full">
            <div className="flex flex-col items-center justify-start w-full gap-6">
              <List
                className="flex flex-col gap-5 items-center pt-[138px] w-full"
                orientation="vertical"
              >
                <div className="flex sm:flex-1 flex-col gap-1.5 items-start justify-start mr-[13px] my-0 w-auto sm:w-full">
                  <Text
                    className="sm:text-[22.94px] md:text-[24.94px] text-[26.94px] text-gray-700_01 w-auto"
                    size="txtInterRegular2694"
                  >
                    Email address*
                  </Text>
                  <div className="border border-black-900 border-solid h-[74px] w-full"></div>
                </div>
                <div className="flex sm:flex-1 flex-col gap-1.5 items-start justify-start ml-1 mr-[9px] my-0 w-auto sm:w-full">
                  <Text
                    className="sm:text-[22.94px] md:text-[24.94px] text-[26.94px] text-gray-700_01 w-auto"
                    size="txtInterRegular2694"
                  >
                    Name*
                  </Text>
                  <div className="border border-black-900 border-solid h-[74px] w-full"></div>
                </div>
                <div className="flex sm:flex-1 flex-col gap-1.5 items-start justify-start ml-[3px] mr-2.5 my-0 w-auto sm:w-full">
                  <Text
                    className="sm:text-[22.94px] md:text-[24.94px] text-[26.94px] text-gray-700_01 w-auto"
                    size="txtInterRegular2694"
                  >
                    Password*
                  </Text>
                  <div className="border border-black-900 border-solid flex flex-col items-end justify-start p-[21px] sm:px-5 w-full">
                    <Img
                      className="h-[30px] mr-[9px] w-[30px]"
                      src="images/img_eye.svg"
                      alt="eye"
                    />
                  </div>
                </div>
                <div className="flex sm:flex-1 flex-col items-start justify-start ml-[3px] mr-2.5 my-0 w-auto sm:w-full">
                  <Text
                    className="sm:text-[22.94px] md:text-[24.94px] text-[26.94px] text-gray-700_01 w-auto"
                    size="txtInterRegular2694"
                  >
                    Confirm password*
                  </Text>
                  <div className="border border-black-900 border-solid flex flex-col items-end justify-start p-[21px] sm:px-5 w-full">
                    <Img
                      className="h-[30px] mr-[9px] w-[30px]"
                      src="images/img_eye.svg"
                      alt="eye"
                    />
                  </div>
                </div>
                <Text
                  className="sm:flex-1 leading-[28.00px] ml-2 my-0 sm:text-[17.35px] md:text-[19.35px] text-[21.35px] text-gray-700_01 w-[99%] sm:w-full"
                  size="txtInterRegular2135"
                >
                  Lorem ipsum dolor sit amet consectetur. Nibh tellus mattis
                  nisi porta lobortis urna eget. Odio ipsum consectetur blandit
                  libero sem.
                </Text>
                <Button
                  className="cursor-pointer font-medium min-w-[569px] sm:min-w-full ml-[3px] mr-3 my-0 sm:text-[27.26px] md:text-[29.26px] text-[31.26px] text-center"
                  shape="square"
                  color="gray_700_01"
                  size="lg"
                  variant="fill"
                >
                  Log In
                </Button>
              </List>
              <Text
                className="sm:text-[19.9px] md:text-[21.9px] text-[23.9px] text-gray-700_01"
                size="txtInterRegular239"
              >
                OR
              </Text>
              <Button
                className="common-pointer border border-gray-700_01 border-solid cursor-pointer flex items-center justify-center min-w-[573px] sm:min-w-full"
                onClick={() => googleSignIn()}
                leftIcon={
                  <Img
                    className="h-[37px] mr-[15px]"
                    src="images/img_icongoogle.svg"
                    alt="Icon-Google"
                  />
                }
                shape="square"
                color="white_A700"
                size="lg"
                variant="fill"
              >
                <div className="font-medium md:text-[21.66px] sm:text-[19.66px] text-[23.66px] text-left">
                  Continue with Google
                </div>
              </Button>
            </div>
            <div className="flex sm:flex-col flex-row font-poppins gap-[9px] items-center justify-center w-[64%] md:w-full">
              <Text
                className="sm:text-[19px] md:text-[21px] text-[23px] text-gray-700_01"
                size="txtPoppinsRegular23"
              >
                Already have a account.
              </Text>
              <a className="text-2xl md:text-[22px] text-gray-700_01 sm:text-xl underline">
                <Text
                  className="common-pointer"
                  size="txtPoppinsMedium24"
                  onClick={() => navigate("/signin")}
                >
                  Sign In
                </Text>
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default SignUpPage;
