import React from "react";
import PropTypes from "prop-types";

const shapes = { round: "rounded-[3px]", square: "rounded-none" };
const variants = {
  fill: {
    gray_200_01: "bg-gray_200_01 text-gray_700",
    gray_700_01: "bg-gray_700_01 text-white_A700",
    white_A700: "bg-white_A700 text-gray_700",
    gray_700: "bg-gray_700 text-white_A700",
    gray_100: "bg-gray_100 text-gray_700_01",
  },
  outline: {
    gray_700_01: "border border-gray_700_01 border-solid text-gray_700_01",
    gray_700: "border border-gray_700 border-solid text-gray_700",
    white_A700: "border border-solid border-white_A700 text-white_A700",
    gray_600: "border border-gray_600 border-solid text-gray_600",
  },
};
const sizes = {
  xs: "p-[5px]",
  sm: "p-2",
  md: "p-[11px]",
  lg: " p-[8px] sm:p-[15px]",
  xl: "p-[18px]",
  "2xl": "p-[21px] sm:px-5",
  "3xl": "p-6 sm:px-5",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape = "",
  size = "",
  variant = "",
  color = "",
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${(shape && shapes[shape]) || ""} ${
        (size && sizes[size]) || ""
      } ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf(["round", "square"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "2xl", "3xl"]),
  variant: PropTypes.oneOf(["fill", "outline"]),
  color: PropTypes.oneOf([
    "gray_200_01",
    "gray_700_01",
    "white_A700",
    "gray_700",
    "gray_100",
    "gray_600",
  ]),
};

export { Button };
