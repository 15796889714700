import React, { useEffect, useState } from "react";

import { Menu, MenuItem, Sidebar, useProSidebar } from "react-pro-sidebar";

import { Button, Img, Text } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, signOut } from "firebase/auth";
import { logOutUser } from "../../../../store/slices/userSlice";
import { toast } from "react-toastify";
// import { collection, deleteDoc, doc, getFirestore } from "firebase/firestore";

const Sidebar1 = (props) => {
  const auth = getAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const seller = useSelector((state) => {
    return state.user.sellerProfile;
  });

  const { collapseSidebar, collapsed } = useProSidebar();
  const [pathArray, setPathArray] = useState([]);
  const { pathname } = useLocation();
  useEffect(() => {
    setPathArray(pathname.replace("/", "").split("-"));
  }, [pathname]);

  // const handleDeleteAccount = async () => {
  //   auth.authStateReady();
  //   if (auth.currentUser) {
  //     try {
  //       await deleteDoc(
  //         doc(collection(getFirestore(), "Sellers"), auth.currentUser.uid)
  //       );
  //       await deleteDoc(
  //         doc(collection(getFirestore(), "Users"), auth.currentUser.uid)
  //       );
  //       await deleteUser(auth.currentUser);
  //       dispatch(logOutUser());
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  return (
    <>
      <Sidebar
        defaultCollapsed={window.screen.width < 768 ? true : false}
        collapsed={collapsed}
        collapsedWidth={window.screen.width < 768 ? "48px" : "80px"}
        className={props.className}
        transitionDuration={500}
      >
        <Menu>
          <MenuItem
            icon={
              <Img
                src="images/align-left1.svg"
                className="sm:h-[33px] h-6  aspect-square"
                alt="Menu"
              />
            }
            onClick={() => {
              collapseSidebar();
            }}
          ></MenuItem>
          <div className={` flex-col items-center justify-center w-full flex `}>
            <div>
              <Img
                className={`${
                  collapsed ? "h-8 sm:h-11" : "h-[119px] "
                } aspect-square mt-9`}
                src={seller?.logo ? seller.logo : "images/defaultLogo.png"}
                alt="seller"
              />
            </div>

            <Text
              className={` text-center flex flex-row gap-1 ${
                collapsed
                  ? " text-[10px] md:text-xs mt-4 md:mt-7"
                  : "text-[22.35px] mt-[40px] "
              } text-white_A700 `}
              size="txtInterMedium2235WhiteA700"
            >
              {seller?.shopName}{" "}
              {seller?.isSellerVerified ? (
                <Img
                  src="images/seller_verified.svg"
                  className={`${
                    collapsed ? "w-2 sm:w-3" : "w-4 sm:w-5"
                  } aspect-square`}
                />
              ) : (
                ""
              )}
            </Text>
          </div>
          <div className="flex flex-col items-start justify-start mb-[50px] mt-[67px] w-full">
            <div
              onClick={() => navigate("/seller-home")}
              className={
                pathArray.includes("home")
                  ? " border-y-2 w-full border-white_A700"
                  : " w-full hover:border-y-2 hover:border-white_A700"
              }
            >
              <MenuItem
                icon={
                  <Img
                    className="sm:h-[33px] h-6  aspect-square"
                    src="images/img_home.svg"
                    alt="home"
                  />
                }
              >
                <Button
                  className="flex flex-row items-center w-full gap-5 bg-transparent cursor-pointer "
                  size="lg"
                >
                  <div className="font-inter font-medium leading-[normal] sm:text-[20.35px] text-[18.35px] md:text-[22.35px] text-left text-white_A700">
                    Home
                  </div>
                </Button>
              </MenuItem>
            </div>
            <div
              onClick={() => navigate("/seller-products")}
              className={
                pathArray.includes("products")
                  ? " border-y-2 w-full border-white_A700"
                  : " w-full hover:border-y-2 hover:border-white_A700"
              }
            >
              <MenuItem
                icon={
                  <Img
                    className=" h-6 sm:h-[33px] aspect-square"
                    src="images/img_grid.svg"
                    alt="grid"
                  />
                }
              >
                <Button
                  className={`flex items-center w-full gap-5 bg-transparent    cursor-pointer `}
                  size="lg"
                >
                  <div className="font-inter font-medium leading-[normal]  sm:text-[20.35px] text-[18.35px] md:text-[22.35px] text-left text-white_A700">
                    Products
                  </div>
                </Button>
              </MenuItem>
            </div>
            <div
              onClick={() => navigate("/analytics")}
              className={
                pathArray.includes("analytics")
                  ? " border-y-2 w-full border-white_A700"
                  : " w-full hover:border-y-2 hover:border-white_A700 "
              }
            >
              <MenuItem
                icon={
                  <Img
                    className="sm:h-[33px] h-6 aspect-square"
                    src="images/img_trendingup.svg"
                    alt="trending-up"
                  />
                }
              >
                <Button
                  className={`flex items-center w-full gap-5 bg-transparent  cursor-pointer`}
                  size="lg"
                >
                  <div className="font-inter font-medium leading-[normal] sm:text-[20.35px] text-[18.35px] md:text-[22.35px] text-left text-white_A700">
                    Analytics
                  </div>
                </Button>
              </MenuItem>
            </div>
            <div
              onClick={() => navigate("/seller-settings")}
              className={
                pathArray.includes("settings")
                  ? " border-y-2 w-full border-white_A700"
                  : " w-full hover:border-y-2 hover:border-white_A700"
              }
            >
              <MenuItem
                icon={
                  <Img
                    className=" sm:h-[33px] h-6 aspect-square"
                    src="images/img_settings_white_a700.svg"
                    alt="settings"
                  />
                }
              >
                <Button
                  className={`flex items-center w-full gap-5 transition-colors duration-300 bg-transparent cursor-pointer  `}
                  size="lg"
                >
                  <div className=" font-inter font-medium leading-[normal] sm:text-[20.35px] text-[18.35px] md:text-[22.35px] text-left text-white_A700">
                    Settings
                  </div>
                  <Img
                    className="sm:h-[33px] h-6 aspect-square"
                    src="images/img_arrowright.svg"
                    alt="arrowright"
                  />
                </Button>
              </MenuItem>
            </div>
          </div>
          <Button
            className="cursor-pointer font-inter font-medium leading-[normal] w-[90%] self-center text-center"
            shape="square"
            color="white_A700"
            size="xs"
            variant="outline"
            onClick={() => {
              signOut(auth)
                .then(() => {
                  localStorage.removeItem("user");
                  localStorage.removeItem("currentStep");
                  dispatch(logOutUser());
                  toast.success("Logged out !");
                  navigate("/seller-signin");
                })
                .catch((error) => {
                  console.log("error", error);
                  window.location.reload();
                });
            }}
          >
            Log out
          </Button>
          {/* <Button onClick={() => handleDeleteAccount()}>Delete</Button> */}
        </Menu>
      </Sidebar>
    </>
  );
};

Sidebar1.defaultProps = {};

export default Sidebar1;
