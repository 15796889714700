import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "category",
  initialState: {
    category: [],
    products: [],
  },
  reducers: {
    setCategoryData(state, action) {
      state.category = action.payload;
    },
    setFilteredData(state, action) {
      state.products = action.payload;
    },
  },
});

export default filterSlice.reducer;
export const { setCategoryData, setFilteredData } = filterSlice.actions;
