import React, { Suspense, lazy } from "react";
import { Outlet } from "react-router-dom";
import PageLoader from "../components/PageLoader";
const Header = lazy(() => import("../components/Header"));
const Footer = lazy(() => import("../components/Footer"));

const BuyersRoute = () => {
  return (
    <div>
      <Suspense fallback={<PageLoader />}>
        <Header />
        <Outlet />
        <Footer className="flex items-center justify-center w-full mt-auto bg-gray_700 md:px-5" />
      </Suspense>
    </div>
  );
};

export default BuyersRoute;
