import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    openLogin: false,
    isLoggedIn: false,
    sellerProfile: null,
  },
  reducers: {
    setOpenLogin(state, action) {
      state.openLogin = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
      state.isLoggedIn = true;
      state.openLogin = false;
    },
    setSellerProfile(state, action) {
      state.sellerProfile = action.payload;
    },
    updateSellerCategories(state, action) {
      if (state.sellerProfile) {
        state.sellerProfile.categories = action.payload;
      }
    },
    setIsSeller(state, action) {
      state.isSeller = action.payload;
    },
    logOutUser(state, action) {
      state.user = null;
      state.sellerProfile = null;
      state.openLogin = false;
      state.isLoggedIn = false;
    },
  },
});

export default userSlice.reducer;

export const {
  setOpenLogin,
  setUser,
  logOutUser,
  setSellerProfile,
  updateSellerCategories,
  setIsSeller,
} = userSlice.actions;
