import React, { useEffect, useRef, useState } from "react";

import { Button, Img, Input, Text } from "../../components";
import Header1 from "../../../../components/Header1";

const ForgotPasswordPage = () => {
  const numberOfDigits = 4;
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const [otpLength, setOtpLength] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [otpError, setOtpError] = useState(false);
  const otpBoxReference = useRef([]);

  const [otpSent, setOtpSent] = useState(false);

  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
    setOtpLength(otp.join("").length);
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
    setOtpLength(otp.join("").length);
  }

  const sendOTP = () => {
    setOtpSent(true);
    setMinutes(1);
    setSeconds(59);
  };
  const handleResendOTP = () => {
    setMinutes(1);
    setSeconds(59);
  };

  const handleVerify = () => {
    console.log(otp);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <>
      <Header1 />
      <div className="bg-white_A700 flex flex-col font-inter items-center justify-start mx-auto pb-[107px] w-full">
        <div className="flex justify-center w-full py-2 border-t-2 border-b-2 sm:py-6 border-gray_700_cc">
          <Text className="text-lg font-medium sm:text-2xl">COME ON IN</Text>
        </div>
        <div className="flex flex-col items-center gap-6 justify-start mt-7 px-5 sm:w-[586px] w-full">
          <Text
            className=" text-[30.04px] sm:text-[32.04px] md:text-[34.04px] text-gray_700_01"
            size="txtInterMedium3404"
          >
            Forgot Password
          </Text>
          <div className="grid w-full text-left">
            <label htmlFor="email">Email address*</label>
            <Input
              fill="White_A700"
              outline="gray_700_01"
              className="w-full px-3 appearance-none focus:outline-none focus:border-2 focus:border-gray_700 h-11"
              value={email}
              onChange={setEmail}
              errors={emailError}
              name="email"
            />
            <Button
              variant="fill"
              color="gray_700"
              className={`px-8 py-2 mx-auto mt-6 w-fit ${
                otpSent ? "hidden" : "block"
              }`}
              onClick={() => sendOTP()}
            >
              Send OTP
            </Button>
          </div>
          <div className={`${otpSent ? "block" : "hidden"}`}>
            <Text
              className=" text-[13.15px] md:text-lg text-gray_700_02"
              size="txtInterRegular18"
            >
              <span className="font-normal text-left text-gray_700_02 font-inter">
                We have send you an{" "}
              </span>
              <span className="font-semibold text-left text-blue_gray_900 font-inter">
                One Time Password(OTP)
              </span>
              <span className="font-normal text-left text-gray_700_02 font-inter">
                .
              </span>
            </Text>
          </div>

          <div
            className={` ${
              otpSent ? "flex" : "hidden"
            } w-full items-center gap-4`}
          >
            {otp.map((digit, index) => (
              <input
                key={index}
                value={digit}
                maxLength={1}
                onChange={(e) => handleChange(e.target.value, index)}
                onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                ref={(reference) =>
                  (otpBoxReference.current[index] = reference)
                }
                className={`border h-10 md:h-14 aspect-square text-gray_700 ${
                  otpError ? "border-red_800" : "border-gray_700"
                }  p-3 block focus:border-2 focus:outline-none appearance-none`}
              />
            ))}
          </div>
          <Text
            className={`text-base text-center text-gray_700_01 ${
              minutes === 0 && seconds === 0 ? "hidden" : "block"
            }`}
            size="txtInterMedium16"
          >
            0{minutes}:{seconds < 10 && "0"}
            {seconds}
          </Text>
          <div className="block ">
            <Text
              className={`text-sm text-center text-black_900 ${
                otpSent ? "block" : "hidden"
              }`}
              size="txtInterRegular14"
            >
              <span className="font-normal text-gray_700_01 font-inter">
                <>I didn&#39;t receive any code.</>
              </span>
              <span className="font-normal text-green_A700_00 font-inter">
                {" "}
              </span>
              <span
                className={`font-medium cursor-pointer ${
                  seconds === 0 && minutes === 0
                    ? "pointer-events-auto"
                    : "pointer-events-none"
                } text-gray_700_01 font-inter`}
                onClick={() => handleResendOTP()}
              >
                RESEND
              </span>
            </Text>
            <Button
              className={`cursor-pointer font-medium px-10 leading-[normal] ${
                otpLength < 4
                  ? "pointer-events-none bg-white_A100 text-gray_700_01"
                  : "pointer-events-auto"
              }  text-base text-center`}
              shape="square"
              color="gray_700_01"
              size="md"
              variant="fill"
              onClick={() => handleVerify()}
            >
              Verify
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordPage;
